import React from 'react';
import { useLocalStorage } from '@uidotdev/usehooks';
import './App.scss';
import { Screen } from './enums/screen.enum';
import HowThatWorksScreen from './screens/HowThatWorksScreen';
import StartScreen from './screens/StartScreen';
import CheckboxesScreen from './screens/CheckboxesScreen';
import CGUScreen from './screens/CGUScreen';
import GDPRScreen from './screens/GDPRScreen';
import CookiesScreen from './screens/CookiesScreen';
import WitnessesScreen from './screens/WitnessesListScreen';
import WitnessesCrudScreen from './screens/WitnessesCrudScreen';
import Step1SummaryScreen from './screens/Step1SummaryScreen';
import Step1ChoiceTypeScreen from './screens/Step1ChoiceTypeScreen';
import Step1DriverScreen from './screens/Step1DriverScreen';
import Step1InsuredScreen from './screens/Step1InsuredScreen';
import Step1VehicleScreen from './screens/Step1VehicleScreen';
import Step2ChoiceTypeScreen from './screens/Step2ChoiceTypeScreen';
import Step2DriverScreen from './screens/Step2DriverScreen';
import Step2InsuredScreen from './screens/Step2InsuredScreen';
import Step2VehicleScreen from './screens/Step2VehicleScreen';
import Step3SummaryScreen from './screens/Step3SummaryScreen';
import Step4PhotosScreen from './screens/Step4PhotosScreen';
import Step5InitialImpactAScreen from './screens/Step5InitialImpactAScreen';
import Step6DamagesAScreen from './screens/Step6DamagesAScreen';
import Step7InitialImpactBScreen from './screens/Step7InitialImpactBScreen';
import Step8DamagesBScreen from './screens/Step8DamagesBScreen';
import Step9ChatbotScreen from './screens/Step9ChatbotScreen';
import Step10SketchCommentsScreen from './screens/Step10SketchCommentsScreen';
import Step11LocalizationScreen from './screens/Step11LocalizationScreen';
import Step12ConstatPreviewScreen from './screens/Step12ConstatPreviewScreen';
import SignatureScreen from './screens/SignatureScreen';
import GreetingsScreen from './screens/GreetingsScreen';
import WoundedListScreen from './screens/WoundedListScreen';
import WoundedCrudScreen from './screens/WoundedCrudScreen';
import Header from './partials/Header';
import { InternalApiService } from './services/internal.api.service';
import { Constat } from './models/Constat';
import { WitnessContext } from 'contexts/WitnessContext';
import { WoundedContext } from 'contexts/WoundedContext';
import { witnessInitialState, witnessReducer } from 'reducers/WitnessReducer';
import { woundedInitialState, woundedReducer } from 'reducers/WoundedReducer';
import DefaultPartnerImage from './assets/images/brand/Logo.svg';
import LogRocket from 'logrocket';
import config from '../src/config';
import { useSearchParams } from 'react-router-dom';

function App() {
  if (config.LOG_ROCKET_APP_ID) {
    LogRocket.init(config.LOG_ROCKET_APP_ID);
  }

  const [screen, setScreen] = useLocalStorage('screen', Screen.start);
  const [, setConstat] = useLocalStorage('constat', new Constat());
  const [, setPartnerImage] = useLocalStorage(
    'partner-image',
    DefaultPartnerImage,
  );
  const [searchParams, setSearchParams] = useSearchParams();

  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (searchParams.has('vehicle')) {
      const internalApiService = new InternalApiService();
      setScreen(Screen.start);
      internalApiService
        .loadVehicleData(
          searchParams.get('vehicle') ?? '',
          setConstat,
          setPartnerImage,
          setLoading,
        )
        .then(() => {
          searchParams.delete('vehicle');
          setSearchParams(searchParams);
        });
    }
  }, []);

  const [WitnessState, WitnessDispatch] = React.useReducer(
    witnessReducer,
    witnessInitialState,
  );
  const [WoundedState, WoundedDispatch] = React.useReducer(
    woundedReducer,
    woundedInitialState,
  );

  return (
    <div className="app">
      <Header
        isMinimized={![Screen.start, Screen.greetings].includes(screen)}
        lastScreen={Screen.greetings === screen}
      />

      <StartScreen
        isActive={screen === Screen.start}
        loading={loading}
        setScreen={setScreen}
      />
      <HowThatWorksScreen
        isActive={screen === Screen.howThatWorks}
        setScreen={setScreen}
      />
      <CheckboxesScreen
        isActive={screen === Screen.checkboxes}
        setScreen={setScreen}
      />
      <CGUScreen isActive={screen === Screen.cgu} setScreen={setScreen} />
      <GDPRScreen isActive={screen === Screen.gdpr} setScreen={setScreen} />
      <CookiesScreen
        isActive={screen === Screen.cookies}
        setScreen={setScreen}
      />

      <WitnessContext.Provider
        value={{ state: WitnessState, dispatch: WitnessDispatch }}
      >
        <WitnessesScreen
          isActive={screen === Screen.witnesses}
          setScreen={setScreen}
        />
        <WitnessesCrudScreen
          isActive={screen === Screen.witnessesCrud}
          setScreen={setScreen}
        />
      </WitnessContext.Provider>

      <WoundedContext.Provider
        value={{ state: WoundedState, dispatch: WoundedDispatch }}
      >
        <WoundedListScreen
          isActive={screen === Screen.woundedList}
          setScreen={setScreen}
        />
        <WoundedCrudScreen
          isActive={screen === Screen.woundedCrud}
          setScreen={setScreen}
        />
      </WoundedContext.Provider>
      <Step1SummaryScreen
        isActive={screen === Screen.step1Summary}
        setScreen={setScreen}
      />
      <Step1ChoiceTypeScreen
        isActive={screen === Screen.step1ChoiceType}
        setScreen={setScreen}
      />
      <Step1DriverScreen
        isActive={screen === Screen.step1Driver}
        setScreen={setScreen}
      />
      <Step1InsuredScreen
        isActive={screen === Screen.step1Insured}
        setScreen={setScreen}
      />
      <Step1VehicleScreen
        isActive={screen === Screen.step1Vehicle}
        setScreen={setScreen}
      />
      <Step2ChoiceTypeScreen
        isActive={screen === Screen.step2ChoiceType}
        setScreen={setScreen}
      />
      <Step2DriverScreen
        isActive={screen === Screen.step2Driver}
        setScreen={setScreen}
      />
      <Step2InsuredScreen
        isActive={screen === Screen.step2Insured}
        setScreen={setScreen}
      />
      <Step2VehicleScreen
        isActive={screen === Screen.step2Vehicle}
        setScreen={setScreen}
      />
      <Step3SummaryScreen
        isActive={screen === Screen.step3Summary}
        setScreen={setScreen}
      />
      <Step4PhotosScreen
        isActive={screen === Screen.step4Photos}
        setScreen={setScreen}
      />
      <Step5InitialImpactAScreen
        isActive={screen === Screen.step5InitialImpactA}
        setScreen={setScreen}
      />
      <Step6DamagesAScreen
        isActive={screen === Screen.step6DamagesA}
        setScreen={setScreen}
      />
      <Step7InitialImpactBScreen
        isActive={screen === Screen.step7InitialImpactB}
        setScreen={setScreen}
      />
      <Step8DamagesBScreen
        isActive={screen === Screen.step8DamagesB}
        setScreen={setScreen}
      />
      <Step9ChatbotScreen
        isActive={screen === Screen.step9Chatbot}
        setScreen={setScreen}
      />
      <Step10SketchCommentsScreen
        isActive={screen === Screen.step10SketchComments}
        setScreen={setScreen}
      />
      <Step11LocalizationScreen
        isActive={screen === Screen.step11Localisation}
        setScreen={setScreen}
      />
      <Step12ConstatPreviewScreen
        isActive={screen === Screen.step12ConstatPreview}
        setScreen={setScreen}
      />
      <SignatureScreen
        isActive={screen === Screen.signature}
        setScreen={setScreen}
      />
      <GreetingsScreen
        isActive={screen === Screen.greetings}
        setScreen={setScreen}
      />
    </div>
  );
}

export default App;
