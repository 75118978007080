import React from 'react';

interface VehicleIconDictionary {
  [type: string]: () => JSX.Element;
}

function VehicleIconAUTO(): JSX.Element {
  return (
    <svg
      width="76"
      height="43"
      viewBox="0 0 76 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M57.75 13H2.25V15.5H57.75V13Z" fill="currentColor" />
      <path d="M35 2H32.5V34.5H35V2Z" fill="currentColor" />
      <path d="M19.25 2.5H16.75V14.25H19.25V2.5Z" fill="currentColor" />
      <path
        d="M74 27.25L67.5 25.75C67 25.5 66.5 25 66.5 24.5V21.5C66.5 21 66.75 20.75 67 20.5L71.75 17.75L73 20L68.75 22.5V23.75L74.25 25L74 27.25Z"
        fill="currentColor"
      />
      <path
        d="M69.75 35.75H67.5V33.25H69.75C71.5 33.25 73 31.5 73 29.5V21.25C71.25 18.75 64.25 15.5 57.5 15.5C57.25 15.5 56.75 15.25 56.5 15.25L53 11.75C47.75 6.25 40.75 3.25 33.25 3.25H27.25C20.25 3.25 14 4 8.25 5.25C5 5.75 3 8.5 3 12.25V31.25C3 32.25 3.75 33.25 4.75 33.25H11V35.75H4.75C2.25 35.75 0.5 33.75 0.5 31.25V12.25C0.5 7.5 3.5 3.75 7.75 2.75C13.75 1.5 20 0.75 27.25 0.75H33.25C41.5 0.75 49.25 4 55 10L58 13C65.5 13.25 73.25 17 75.25 20.25C75.25 20.5 75.5 20.75 75.5 21V29.5C75.5 33 73 35.75 69.75 35.75Z"
        fill="currentColor"
      />
      <path d="M53.5 33.25H24.25V35.75H53.5V33.25Z" fill="currentColor" />
      <path
        d="M2 27V24.5C4.25 24.5 6 23 6.5 21H2.25V18.5H8C8.75 18.5 9.25 19 9.25 19.75C9.25 23.75 6 27 2 27Z"
        fill="currentColor"
      />
      <path
        d="M60.5 42.75C56 42.75 52.25 39 52.25 34.5C52.25 30 56 26.25 60.5 26.25C65 26.25 68.75 30 68.75 34.5C68.75 39 65 42.75 60.5 42.75ZM60.5 28.75C57.25 28.75 54.75 31.25 54.75 34.5C54.75 37.75 57.25 40.25 60.5 40.25C63.75 40.25 66.25 37.75 66.25 34.5C66.25 31.25 63.75 28.75 60.5 28.75Z"
        fill="currentColor"
      />
      <path
        d="M60.5 38C58.5 38 57 36.5 57 34.5C57 32.5 58.5 31 60.5 31C62.5 31 64 32.5 64 34.5C64 36.5 62.5 38 60.5 38ZM60.5 33.5C60 33.5 59.5 34 59.5 34.5C59.5 35 60 35.5 60.5 35.5C61 35.5 61.5 35 61.5 34.5C61.5 34 61 33.5 60.5 33.5Z"
        fill="currentColor"
      />
      <path
        d="M17.75 42.75C13.25 42.75 9.5 39 9.5 34.5C9.5 30 13.25 26.25 17.75 26.25C22.25 26.25 26 30 26 34.5C26 39 22.25 42.75 17.75 42.75ZM17.75 28.75C14.5 28.75 12 31.25 12 34.5C12 37.75 14.5 40.25 17.75 40.25C21 40.25 23.5 37.75 23.5 34.5C23.5 31.25 21 28.75 17.75 28.75Z"
        fill="currentColor"
      />
      <path
        d="M17.75 38C15.75 38 14.25 36.5 14.25 34.5C14.25 32.5 15.75 31 17.75 31C19.75 31 21.25 32.5 21.25 34.5C21.25 36.5 19.75 38 17.75 38ZM17.75 33.5C17.25 33.5 16.75 34 16.75 34.5C16.75 35 17.25 35.5 17.75 35.5C18.25 35.5 18.75 35 18.75 34.5C18.75 34 18.25 33.5 17.75 33.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

function VehicleIconTRUCK(): JSX.Element {
  return (
    <svg
      width="75"
      height="51"
      viewBox="0 0 75 51"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 44.75H33V42.25H48.75V34.75H2.75V42.25H6.25V44.75H1.5C0.75 44.75 0.25 44.25 0.25 43.5V33.5C0.25 32.75 0.75 32.25 1.5 32.25H50C50.75 32.25 51.25 32.75 51.25 33.5V43.5C51.25 44.25 50.75 44.75 50 44.75Z"
        fill="currentColor"
      />
      <path d="M22 42.25H17.5V44.75H22V42.25Z" fill="currentColor" />
      <path
        d="M63.25 50.25C59.5 50.25 56.5 47.25 56.5 43.5C56.5 39.75 59.5 36.75 63.25 36.75C67 36.75 70 39.75 70 43.5C70 47.25 67 50.25 63.25 50.25ZM63.25 39.25C61 39.25 59 41.25 59 43.5C59 45.75 61 47.75 63.25 47.75C65.75 47.75 67.5 46 67.5 43.5C67.5 41.25 65.5 39.25 63.25 39.25Z"
        fill="currentColor"
      />
      <path
        d="M27.5 50.25C23.75 50.25 20.75 47.25 20.75 43.5C20.75 39.75 23.75 36.75 27.5 36.75C31.25 36.75 34.25 39.75 34.25 43.5C34.25 47.25 31.25 50.25 27.5 50.25ZM27.5 39.25C25.25 39.25 23.25 41.25 23.25 43.5C23.25 45.75 25.25 47.75 27.5 47.75C29.75 47.75 31.75 45.75 31.75 43.5C31.75 41.25 29.75 39.25 27.5 39.25Z"
        fill="currentColor"
      />
      <path
        d="M12 50.25C8.25 50.25 5.25 47.25 5.25 43.5C5.25 39.75 8.25 36.75 12 36.75C15.75 36.75 18.75 39.75 18.75 43.5C18.75 47.25 15.75 50.25 12 50.25ZM12 39.25C9.75 39.25 7.75 41.25 7.75 43.5C7.75 45.75 9.75 47.75 12 47.75C14.25 47.75 16.25 45.75 16.25 43.5C16.25 41.25 14.25 39.25 12 39.25Z"
        fill="currentColor"
      />
      <path
        d="M73 44.75H68.75V42.25H71.75V29.5C71.75 27.75 70.25 26 68.5 26C68 26 67.5 25.75 67.25 25.25L62.75 13.75C62 12.5 61.25 12.25 60.75 12.25H51.75V42.25H58V44.75H50.5C49.75 44.75 49.25 44.25 49.25 43.5V11C49.25 10.25 49.75 9.75 50.5 9.75H60.75C62.5 9.75 64 10.75 65 12.5L69.25 23.5C72 24 74 26.5 74 29.5V43.5C74.25 44.25 73.75 44.75 73 44.75Z"
        fill="currentColor"
      />
      <path
        d="M68.25 26H57.25C55.25 26 53.75 24.5 53.75 22.5V16.75C53.75 16 54.25 15.5 55 15.5H64.5V18H56.25V22.5C56.25 23 56.75 23.5 57.25 23.5H68.25V26Z"
        fill="currentColor"
      />
      <path
        d="M2.75 33.5H0.25V1.25C0.25 0.5 0.75 0 1.5 0H50C50.75 0 51.25 0.5 51.25 1.25V12.25H48.75V2.5H2.75V33.5Z"
        fill="currentColor"
      />
      <path
        d="M32.0563 0.239715L5.23511 32.5587L7.15886 34.1552L33.9801 1.83621L32.0563 0.239715Z"
        fill="currentColor"
      />
      <path d="M58.5 28.75H54V31.25H58.5V28.75Z" fill="currentColor" />
    </svg>
  );
}

function VehicleIconVUL(): JSX.Element {
  return (
    <svg
      width="76"
      height="45"
      viewBox="0 0 76 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M58.5 22.5H54.25V25H58.5V22.5Z" fill="currentColor" />
      <path
        d="M74 31H73.5V23.5C73.5 20.75 71.5 18.25 68.75 17.75L62.75 3.25C62 1.5 60.25 0.5 58.5 0.5H51C51 0.5 51 0.5 50.75 0.5H4.25C3.5 0.5 3 1 3 1.75V30.75H2C1.25 30.75 0.75 31.25 0.75 32V36.75C0.75 37.5 1.25 38 2 38H2.25H14.25C15 41.5 18.25 44.25 22 44.25C25.75 44.25 29 41.5 29.75 38H53.5C54.25 41.5 57.5 44.25 61.25 44.25C65 44.25 68 41.5 69 38H74C74.75 38 75.25 37.5 75.25 36.75V32C75.25 31.5 74.75 31 74 31ZM71 28H70.25C69.25 28 68.25 27.25 68.25 26.25C68.25 25.25 69 24.5 70.25 24.5H71V28ZM66 17.75H57.5C57 17.75 56.75 17.25 56.75 17V9H62.5L66 17.75ZM60.5 4.25L61.25 6.25H55.25C54.5 6.25 54 6.75 54 7.5V16.75C54 18.5 55.5 20 57.25 20H68C69.25 20 70.25 20.75 70.75 21.75H70.25C67.75 21.75 65.75 23.75 65.75 26C65.75 28.25 67.75 30.25 70.25 30.25H71V31H66.75C65.25 29.5 63.25 28.5 61 28.5C58.75 28.5 56.75 29.5 55.5 30.75H51.75V3H58.25C59.25 3 60 3.5 60.5 4.25ZM38.75 30.75H29.25V8.75H38.75V30.75ZM29.25 33.25H54C53.75 34 53.5 34.75 53.5 35.5H30C29.75 34.75 29.5 34 29.25 33.25ZM3.25 33.25H14.75C14.5 34 14.25 34.75 14.25 35.5H3.25V33.25ZM16.25 31H5.5V3H49.5V30.75H41.25V7.5C41.25 6.75 40.75 6.25 40 6.25H28C27.25 6.25 26.75 6.75 26.75 7.5V16.5H13.25V19H26.5V29.75C25.25 28.75 23.5 28.25 22 28.25C19.75 28.5 17.75 29.5 16.25 31ZM22 41.75C19 41.75 16.5 39.25 16.5 36.25C16.5 33.25 19 30.75 22 30.75C25 30.75 27.5 33.25 27.5 36.25C27.25 39.25 25 41.75 22 41.75ZM61.25 41.75C58.25 41.75 55.75 39.25 55.75 36.25C55.75 33.25 58.25 30.75 61.25 30.75C64.25 30.75 66.75 33.25 66.75 36.25C66.5 39.25 64.25 41.75 61.25 41.75ZM72.75 35.75H69C69 35 68.75 34.25 68.5 33.5H72.75V35.75Z"
        fill="currentColor"
      />
      <path
        d="M61.25 33C59.5 33 57.75 34.5 57.75 36.5C57.75 38.5 59.25 40 61.25 40C63.25 40 64.75 38.5 64.75 36.5C64.75 34.5 63 33 61.25 33ZM61.25 37.25C60.75 37.25 60.25 36.75 60.25 36.25C60.25 35.75 60.75 35.25 61.25 35.25C61.75 35.25 62.25 35.75 62.25 36.25C62.25 36.75 61.75 37.25 61.25 37.25Z"
        fill="currentColor"
      />
      <path
        d="M22 33C20.25 33 18.5 34.5 18.5 36.5C18.5 38.5 20 40 22 40C24 40 25.5 38.5 25.5 36.5C25.5 34.5 23.75 33 22 33ZM22 37.25C21.5 37.25 21 36.75 21 36.25C21 35.75 21.5 35.25 22 35.25C22.5 35.25 23 35.75 23 36.25C23 36.75 22.5 37.25 22 37.25Z"
        fill="currentColor"
      />
    </svg>
  );
}

function VehicleIconMOTO(): JSX.Element {
  return (
    <svg
      width="76"
      height="43"
      viewBox="0 0 76 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M63 32L51 2.75H47.5V0.25H51.75C52.25 0.25 52.75 0.5 53 1L65.5 31L63 32Z"
        fill="currentColor"
      />
      <path
        d="M40.75 25.25C32.75 25.25 23.75 21.25 23.75 15.25C23.75 9.25 32.75 5.25 40.75 5.25C49.75 5.25 53 10.75 53 15.25C53 20.25 49.25 25.25 40.75 25.25ZM40.75 7.75C34.25 7.75 26.25 11 26.25 15.25C26.25 19.5 34.25 22.75 40.75 22.75C46.75 22.75 50.5 19.75 50.5 15.25C50.5 10.5 46.75 7.75 40.75 7.75Z"
        fill="currentColor"
      />
      <path
        d="M25.75 19H12C7.25 19 2.75 16.25 0.75 12L0.5 11C0.25 10.5 0.25 10 0.5 9.75C0.75 9.5 1.25 9.25 1.75 9.25L26 11.5L25.75 14L3.75 12C5.5 14.75 8.75 16.5 12 16.5H25.75V19Z"
        fill="currentColor"
      />
      <path
        d="M33.5 38.75H19.75V36.25H33.5C40.25 36.25 46 30.5 46 23.75V22.5H48.5V23.75C48.5 32 41.75 38.75 33.5 38.75Z"
        fill="currentColor"
      />
      <path
        d="M31.25 33.75H22.25V31.25H31.25C36 31.25 39.25 28.25 39.5 24H42C41.75 29.75 37.25 33.75 31.25 33.75Z"
        fill="currentColor"
      />
      <path
        d="M26.75 33.75H23.5V31.25H25.5V18.75H28V32.5C28 33.25 27.5 33.75 26.75 33.75Z"
        fill="currentColor"
      />
      <path d="M13.25 18H10.75V21.25H13.25V18Z" fill="currentColor" />
      <path d="M57 11.25H51.25V13.75H57V11.25Z" fill="currentColor" />
      <path d="M59.25 16.5H52.25V19H59.25V16.5Z" fill="currentColor" />
      <path
        d="M12 37.25C8.75 37.25 6.25 34.75 6.25 31.5C6.25 28.25 8.75 25.75 12 25.75C15.25 25.75 17.75 28.25 17.75 31.5C17.75 34.75 15.25 37.25 12 37.25ZM12 28.25C10.25 28.25 8.75 29.75 8.75 31.5C8.75 33.25 10.25 34.75 12 34.75C13.75 34.75 15.25 33.25 15.25 31.5C15.25 29.75 13.75 28.25 12 28.25Z"
        fill="currentColor"
      />
      <path
        d="M12 43C5.5 43 0.5 38 0.5 31.5C0.5 25 5.5 20 12 20C18.5 20 23.5 25 23.5 31.5C23.5 38 18.5 43 12 43ZM12 22.5C7 22.5 3 26.5 3 31.5C3 36.5 7 40.5 12 40.5C17 40.5 21 36.5 21 31.5C21 26.5 17 22.5 12 22.5Z"
        fill="currentColor"
      />
      <path
        d="M64.25 37.25C61 37.25 58.5 34.75 58.5 31.5C58.5 28.25 61 25.75 64.25 25.75C67.5 25.75 70 28.25 70 31.5C70 34.75 67.5 37.25 64.25 37.25ZM64.25 28.25C62.5 28.25 61 29.75 61 31.5C61 33.25 62.5 34.75 64.25 34.75C66 34.75 67.5 33.25 67.5 31.5C67.5 29.75 66 28.25 64.25 28.25Z"
        fill="currentColor"
      />
      <path
        d="M64.25 43C58 43 52.75 37.75 52.75 31.5C52.75 25.25 58 20 64.25 20C70.5 20 75.75 25.25 75.75 31.5C75.75 37.75 70.5 43 64.25 43ZM64.25 22.5C59.25 22.5 55.25 26.5 55.25 31.5C55.25 36.5 59.25 40.5 64.25 40.5C69.25 40.5 73.25 36.5 73.25 31.5C73.25 26.5 69.25 22.5 64.25 22.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

function VehicleIconBIKE(): JSX.Element {
  return (
    <svg
      width="76"
      height="45"
      viewBox="0 0 76 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 30H5C5 24.75 9.5 20.25 14.75 20.25V22.75C10.75 22.75 7.5 26 7.5 30Z"
        fill="currentColor"
      />
      <path
        d="M61 44.5C59.5 44.5 58 44.25 56.5 43.75C52.75 42.5 49.75 40 48 36.5C46.25 33 45.75 29 47 25.5C49.5 17.75 57.75 13.5 65.25 16C73 18.75 77 26.75 74.75 34.25C73.5 38 71 41 67.5 42.75C65.5 43.75 63.25 44.5 61 44.5ZM61 17.5001C56 17.5001 51 20.75 49.5 26C48.5 29 48.75 32.25 50.25 35.25C51.75 38 54.25 40.25 57.25 41.25C60.25 42.25 63.5 42 66.5 40.5C69.25 39 71.5 36.5 72.5 33.5C74.25 27.25 70.75 20.5 64.5 18.25C63.5 17.75 62.25 17.5001 61 17.5001Z"
        fill="currentColor"
      />
      <path
        d="M37 35C34.25 35 32 32.75 32 30C32 27 34 25 37 25C38 24.75 39.25 25.25 40.25 26C41.25 27 42 28.5 42 30C42 32.75 39.75 35 37 35ZM37.5 27.5C37.25 27.5 37.25 27.5 37.5 27.5C35.5 27.5 34.75 28.5 34.75 30C34.75 31.75 36 32.5 37.25 32.5C39 32.5 39.75 31.25 39.75 30C39.75 29.25 39.5 28.5 39 28C38.25 27.75 37.75 27.5 37.5 27.5Z"
        fill="currentColor"
      />
      <path
        d="M30.5669 4.85699L28.1897 5.63049L34.9191 26.3126L37.2964 25.5391L30.5669 4.85699Z"
        fill="currentColor"
      />
      <path
        d="M33.25 31.25H15C14.5 31.25 14 31 13.75 30.5C13.5 30 13.75 29.5 14 29.25L30.25 11C30.5 10.75 30.5 10.75 30.75 10.75L48.25 5L49 7.5L31.75 13.25L17.5 29.25H33V31.25H33.25Z"
        fill="currentColor"
      />
      <path
        d="M60.5 30.75L45.5 2.25C45.25 1.75 45.25 1.5 45.5 1C45.75 0.75 46.25 0.5 46.5 0.5H56.75C58.75 0.5 60.5 2.25 60.5 4.25C60.5 6.25 58.75 8 56.75 8L56.5 5.5C57.25 5.5 58 4.75 58 4.25C58 3.75 57.25 3 56.75 3H48.5L62.5 29.75L60.5 30.75Z"
        fill="currentColor"
      />
      <path
        d="M49.7063 9.31069L38.2161 26.2867L40.2863 27.688L51.7766 10.7119L49.7063 9.31069Z"
        fill="currentColor"
      />
      <path d="M33.25 3.75H25.25V6.25H33.25V3.75Z" fill="currentColor" />
      <path d="M38.25 30H35.75V38.25H38.25V30Z" fill="currentColor" />
      <path d="M40.25 37H33.5V39.5H40.25V37Z" fill="currentColor" />
      <path
        d="M14.75 44.5C6.75 44.5 0.25 38 0.25 30C0.25 22 6.75 15.5 14.75 15.5C18.5 15.25 22.25 16.75 24.75 19.5C27.5 22.25 29.25 26 29.25 30C29.25 38 22.75 44.5 14.75 44.5ZM15.25 18C15 18 15 18 14.75 18C8.25 18 2.75 23.5 2.75 30C2.75 36.5 8.25 42 14.75 42C21.25 42 26.75 36.5 26.75 30C26.75 26.5 25.5 23.5 23.25 21.25C21 19 18.25 18 15.25 18Z"
        fill="currentColor"
      />
      <path
        d="M54.25 27.5L51.75 26.75C53.25 22 58.75 19 63.75 20.5L63 23C59.5 22 55.25 24 54.25 27.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

function VehicleIconSCOOTER(): JSX.Element {
  return (
    <svg
      width="62"
      height="60"
      viewBox="0 0 62 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79688 60C3.57812 60 0.0625 56.4844 0.0625 52.2656C0.0625 48.0469 3.57812 44.5312 7.79688 44.5312C12.0156 44.5312 15.5312 48.0469 15.5312 52.2656C15.5312 56.4844 12.0156 60 7.79688 60ZM7.79688 46.875C4.98438 46.875 2.40625 49.4531 2.40625 52.2656C2.40625 55.3125 4.75 57.6562 7.79688 57.6562C10.6094 57.6562 13.1875 55.0781 13.1875 52.2656C13.1875 49.4531 10.6094 46.875 7.79688 46.875Z"
        fill="currentColor"
      />
      <path
        d="M54.2031 60C49.9844 60 46.4688 56.4844 46.4688 52.2656C46.4688 48.0469 49.9844 44.5312 54.2031 44.5312C58.4219 44.5312 61.9375 48.0469 61.9375 52.2656C61.9375 56.4844 58.4219 60 54.2031 60ZM54.2031 46.875C51.3906 46.875 48.8125 49.4531 48.8125 52.2656C48.8125 55.0781 51.3906 57.6562 54.2031 57.6562C57.0156 57.6562 59.5938 55.0781 59.5938 52.2656C59.5938 49.4531 57.0156 46.875 54.2031 46.875Z"
        fill="currentColor"
      />
      <path
        d="M44.125 55.7812H19.0469C18.3437 55.7812 17.875 55.3125 17.875 54.6094V50.3906C17.875 49.6875 18.3437 49.2187 19.0469 49.2187H42.9531C44.3594 44.2969 48.8125 41.0156 53.9688 41.0156C55.8438 41.0156 57.7188 41.4844 59.3594 42.4219L58.4219 44.5312C57.0156 43.8281 55.375 43.5937 53.9688 43.5937C49.75 43.5937 46 46.6406 45.0625 50.625H45.2969V54.8437C45.2969 55.3125 44.8281 55.7812 44.125 55.7812ZM20.2188 53.4375H42.9531V51.5625H20.2188V53.4375Z"
        fill="currentColor"
      />
      <path
        d="M19.0469 51.0938H14.3594V53.4375H19.0469V51.0938Z"
        fill="currentColor"
      />
      <path
        d="M47.6406 51.0938H44.3594V53.4375H47.6406V51.0938Z"
        fill="currentColor"
      />
      <path
        d="M18.9476 6.11119L14.5168 24.088L16.7924 24.6489L21.2232 6.67205L18.9476 6.11119Z"
        fill="currentColor"
      />
      <path
        d="M12.7187 47.3438L10.375 46.875L15.2969 26.9531C15.2969 26.4844 15.0625 26.0156 14.5938 26.0156C14.3594 26.0156 13.8906 25.7813 13.4219 26.7188L8.5 45.9375L6.15625 45.4688L11.0781 26.0156C11.7812 23.9063 13.4219 23.2031 15.2969 23.9062C16.7031 24.1406 17.6406 25.5469 17.6406 27.1875V27.6563V27.8906L12.7187 47.3438Z"
        fill="currentColor"
      />
      <path
        d="M20.6875 7.5C18.5781 7.5 16.9375 5.85938 16.9375 3.75C16.9375 1.64063 18.5781 0 20.6875 0C22.7969 0 24.4375 2.10938 24.4375 3.75C24.4375 5.85938 22.7969 7.5 20.6875 7.5ZM20.6875 2.34375C19.9844 2.34375 19.2812 3.04688 19.2812 3.75C19.2812 4.45313 19.9844 5.15625 20.6875 5.15625C21.3906 5.15625 22.0937 4.45313 22.0937 3.75C22.0937 3.28125 21.3906 2.34375 20.6875 2.34375Z"
        fill="currentColor"
      />
      <path
        d="M7.5625 55.7812C5.6875 55.7812 4.04688 54.1406 4.04688 52.2656C4.04688 50.3906 5.6875 48.75 7.5625 48.75C9.4375 48.75 11.0781 50.3906 11.0781 52.2656C11.0781 54.1406 9.4375 55.7812 7.5625 55.7812ZM7.5625 51.0938C7.09375 51.0938 6.39062 51.5625 6.39062 52.2656C6.39062 52.7344 7.09375 53.4375 7.5625 53.4375C8.03125 53.4375 8.73438 52.9688 8.73438 52.2656C8.73438 51.7969 8.03125 51.0938 7.5625 51.0938Z"
        fill="currentColor"
      />
      <path
        d="M54.2031 55.7812C52.3281 55.7812 50.6875 54.1406 50.6875 52.2656C50.6875 50.3906 52.3281 48.75 54.2031 48.75C56.0781 48.75 57.7188 50.3906 57.7188 52.2656C57.7188 54.1406 56.0781 55.7812 54.2031 55.7812ZM54.2031 51.0938C53.7344 51.0938 53.0312 51.5625 53.0312 52.2656C53.0312 52.7344 53.7344 53.4375 54.2031 53.4375C54.6719 53.4375 55.375 52.9688 55.375 52.2656C55.375 51.7969 54.6719 51.0938 54.2031 51.0938Z"
        fill="currentColor"
      />
    </svg>
  );
}

const VehicleIconProvider: VehicleIconDictionary = {
  AUTO: VehicleIconAUTO,
  TRUCK: VehicleIconTRUCK,
  VUL: VehicleIconVUL,
  MOTO: VehicleIconMOTO,
  BIKE: VehicleIconBIKE,
  SCOOTER: VehicleIconSCOOTER,
};

/**
 * Vehicle Icon properties
 */
type VehicleIconProps = {
  shape?: string;
};

/**
 * Vehicle Icon component
 */
export function VehicleIcon({ shape }: VehicleIconProps): JSX.Element {
  return VehicleIconProvider[
    shape && VehicleIconProvider.hasOwnProperty(shape) ? shape : 'AUTO'
  ]();
}
