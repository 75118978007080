import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import Illustration from '../components/Illustration';
import DateHeure from '../assets/images/content/date-heure.png';
import { Constat } from '../models/Constat';
import { useLocalStorage } from '@uidotdev/usehooks';
import { InputField, InputFieldType } from '../components/InputField';
import { Form } from '../components/Form';
import { NominatimApiService } from '../services/nominatim.api.service';
import { ConstatUtilsService } from '../services/constat-utils.service';

type PartialGeolocationData = {
  address?: {
    road?: string;
    postcode?: string;
    town?: string;
    village?: string;
    municipality?: string;
  };
};

export default function Step11LocalizationScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const constatUtilsService = new ConstatUtilsService();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());
  const [dateValid, setDateValid] = React.useState(
    !constat?.dateOfAccident ||
      constatUtilsService.validDate(constat?.dateOfAccident),
  );
  const [hourValid, setHourValid] = React.useState(
    !constat?.hourOfAccident ||
      constatUtilsService.validHour(constat?.hourOfAccident),
  );
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (isActive) {
      const now = new Date();
      setConstat((prevConstat: Constat) => {
        if (!prevConstat.dateOfAccident) {
          prevConstat.dateOfAccident =
            String(now.getDate()).padStart(2, '0') +
            '/' +
            String(now.getMonth() + 1).padStart(2, '0') +
            '/' +
            now.getFullYear();
        }
        if (!prevConstat.hourOfAccident) {
          prevConstat.hourOfAccident =
            String(now.getHours()) +
            'h' +
            String(now.getMinutes()).padStart(2, '0');
        }

        return prevConstat;
      });
    }
  }, [isActive]);

  const handleChange = (fieldName: string, value: any) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      newConstat.setField(fieldName, value);

      return newConstat;
    });
  };

  const localize = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        const nominatimService = new NominatimApiService();
        nominatimService
          .reverse(
            position.coords.latitude.toString(),
            position.coords.longitude.toString(),
            setLoading,
          )
          .then((data: PartialGeolocationData | false) => {
            if (false !== data) {
              handleChange(
                'localityExactLocation',
                [
                  data.address?.road,
                  data.address?.postcode,
                  data.address?.town ??
                    data.address?.village ??
                    data.address?.municipality,
                ]
                  .filter((part?: string) => !!part)
                  .join(' '),
              );
            }
          });
      });
    }
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step11-localization.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={11}
      extraCssClasses={['screen-step', 'step11-localization']}
    >
      <ContentContainer>
        <Illustration.Image src={DateHeure} alt=""></Illustration.Image>

        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <InputField
            name="dateOfAccident"
            id="dateOfAccident"
            type={InputFieldType.Text}
            required={true}
            label={t('constat.label.dateOfAccident')}
            value={constat.dateOfAccident || ''}
            setValid={setDateValid}
            validateWith={constatUtilsService.validDate}
            onChange={(value) => handleChange('dateOfAccident', value)}
          />
          <InputField
            name="hourOfAccident"
            id="hourOfAccident"
            type={InputFieldType.Text}
            required={true}
            label={t('constat.label.hourOfAccident')}
            value={constat.hourOfAccident || ''}
            setValid={setHourValid}
            validateWith={constatUtilsService.validHour}
            onChange={(value) => handleChange('hourOfAccident', value)}
          />
          <React.Fragment key={loading ? 'loading' : 'not-loading'}>
            <InputField
              name="localityExactLocation"
              id="localityExactLocation"
              type={InputFieldType.GooglePlaces}
              required={true}
              label={t('constat.label.localityExactLocation')}
              value={constat.localityExactLocation || ''}
              onChange={(value) => handleChange('localityExactLocation', value)}
            />
          </React.Fragment>
        </Form>
        <Button variant={ButtonVariant.Green} onClick={localize}>
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.4189 4.98401C9.92449 4.98401 9.44114 5.13063 9.03002 5.40533C8.6189 5.68004 8.29847 6.07048 8.10925 6.5273C7.92003 6.98412 7.87052 7.48678 7.96698 7.97173C8.06345 8.45669 8.30155 8.90214 8.65118 9.25178C9.00081 9.60141 9.44627 9.83951 9.93122 9.93597C10.4162 10.0324 10.9188 9.98293 11.3757 9.79371C11.8325 9.60449 12.2229 9.28406 12.4976 8.87293C12.7723 8.46181 12.9189 7.97846 12.9189 7.48401C12.9182 6.8212 12.6546 6.18575 12.1859 5.71708C11.7172 5.2484 11.0818 4.98476 10.4189 4.98401ZM10.4189 8.73401C10.1717 8.73401 9.93004 8.6607 9.72448 8.52335C9.51892 8.38599 9.35871 8.19077 9.2641 7.96236C9.16949 7.73396 9.14473 7.48262 9.19296 7.24015C9.2412 6.99767 9.36025 6.77494 9.53506 6.60013C9.70988 6.42531 9.93261 6.30626 10.1751 6.25803C10.4176 6.2098 10.6689 6.23455 10.8973 6.32916C11.1257 6.42377 11.3209 6.58398 11.4583 6.78955C11.5956 6.99511 11.6689 7.23678 11.6689 7.48401C11.6686 7.81541 11.5367 8.13313 11.3024 8.36747C11.0681 8.60181 10.7503 8.73363 10.4189 8.73401Z"
              fill="currentColor"
            />
            <path
              d="M15.2232 2.6764C14.0354 1.48891 12.4498 0.7835 10.7725 0.696302C9.09524 0.609105 7.44509 1.1463 6.14057 2.20422C4.83605 3.26214 3.96965 4.76577 3.70857 6.42493C3.4475 8.08408 3.81026 9.78113 4.72685 11.1885L9.44978 18.4391C9.55488 18.6004 9.69859 18.7329 9.86787 18.8247C10.0372 18.9165 10.2267 18.9645 10.4192 18.9645C10.6118 18.9645 10.8013 18.9165 10.9706 18.8247C11.1398 18.7329 11.2835 18.6004 11.3886 18.4391L16.1117 11.1885C16.9622 9.88296 17.3381 8.32471 17.1763 6.77497C17.0145 5.22523 16.325 3.77819 15.2232 2.6764ZM15.0644 10.5062L10.4192 17.6371L5.77404 10.5062C4.35216 8.32347 4.65712 5.40237 6.49915 3.56026C7.01394 3.04546 7.62509 2.6371 8.29769 2.35849C8.9703 2.07988 9.6912 1.93648 10.4192 1.93648C11.1473 1.93648 11.8682 2.07988 12.5408 2.35849C13.2134 2.6371 13.8245 3.04546 14.3393 3.56026C16.1813 5.40237 16.4863 8.32347 15.0644 10.5062Z"
              fill="currentColor"
            />
          </svg>
          {t('screens.step11-localization.localizeMe')}
        </Button>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step10SketchComments)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={
              !constatUtilsService.isConstatLocalizationDataComplete(
                new Constat(constat),
              ) ||
              !dateValid ||
              !hourValid
            }
            onClick={() => setScreen(Screen.step12ConstatPreview)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
