import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InternalApiService } from '../services/internal.api.service';
import Illustration from './Illustration';
import loadingImage from '../assets/images/loading.svg';
import { Button, ButtonSize, ButtonType, ButtonVariant } from './Button';

/**
 * Photos Uploader properties
 */
type PhotosUploaderProps = {
  id?: string;
  name?: string;
  photos?: string[];
  onPhotosChange?: (photos: string[]) => void;
  multiple: boolean;
};

/**
 * Upload photos component
 */
export function PhotosUploader({
  id,
  name,
  photos = [],
  onPhotosChange,
  multiple = false,
}: PhotosUploaderProps): JSX.Element {
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);
  const [dragActive, setDragActive] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const internalApiService = new InternalApiService();

  const handleFile = (files: FileList) => {
    setError(false);
    Array.from(files).forEach((file: File) =>
      internalApiService
        .uploadFile(file, setLoading)
        .then((path: false | string) => {
          setError(false === path);
          if (path !== false) {
            if (multiple) {
              photos.push(path);
            } else {
              photos = [path];
            }
            if (onPhotosChange) {
              onPhotosChange(photos);
            }
          }
        }),
    );
  };

  const handleDrag = (e: React.DragEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = (e: React.DragEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  // triggers the input when the button is clicked
  const onButtonClick = () => {
    inputRef?.current?.click();
  };

  const removeFile = (index: number) => {
    if (photos?.length && index > -1 && index < photos.length) {
      photos.splice(index, 1);
      if (onPhotosChange) {
        onPhotosChange(photos);
      }
    }
  };

  return (
    <div className={'photos-uploader'}>
      {error && (
        <>
          <div className={'error'}>
            <Trans i18nKey={'photos-upload.error'}></Trans>
            <Button
              variant={ButtonVariant.Secondary}
              type={ButtonType.Icon}
              onClick={() => setError(false)}
            >
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.6919 4.19193L15.808 3.30811L10 9.11615L4.19195 3.30811L3.30804 4.19193L9.11613 10L3.30804 15.8081L4.19195 16.6919L10 10.8839L15.808 16.6919L16.6919 15.8081L10.8839 10L16.6919 4.19193Z"
                  fill="currentColor"
                />
              </svg>
            </Button>
          </div>
        </>
      )}
      {photos && photos.length > 0 && (
        <div className={'photos'}>
          {photos.map((photo: string, index: number) => (
            <div key={index} className={'photo'}>
              <Button
                variant={ButtonVariant.Secondary}
                type={ButtonType.Icon}
                onClick={() => removeFile(index)}
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.6919 4.19193L15.808 3.30811L10 9.11615L4.19195 3.30811L3.30804 4.19193L9.11613 10L3.30804 15.8081L4.19195 16.6919L10 10.8839L15.808 16.6919L16.6919 15.8081L10.8839 10L16.6919 4.19193Z"
                    fill="currentColor"
                  />
                </svg>
              </Button>
              <Illustration.Image src={photo} alt=""></Illustration.Image>
            </div>
          ))}
        </div>
      )}
      {loading ? (
        <Illustration.Loader src={loadingImage} alt=""></Illustration.Loader>
      ) : (
        <form onDragEnter={handleDrag} onSubmit={(e) => e.preventDefault()}>
          <input
            ref={inputRef}
            id={id}
            name={name}
            type="file"
            accept="image/*"
            multiple={multiple}
            onChange={handleChange}
          />
          <label htmlFor={id} className={dragActive ? 'drag-active' : ''}>
            <Button
              variant={ButtonVariant.Green}
              onClick={onButtonClick}
              size={ButtonSize.Medium}
            >
              <svg
                width="50"
                height="47"
                viewBox="0 0 50 47"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M46.0931 7.33752H37.3821L35.796 2.77209C35.5827 2.15903 35.1839 1.62754 34.6549 1.25132C34.126 0.875098 33.4931 0.672803 32.8439 0.672485H17.1134C16.4608 0.672353 15.8246 0.876575 15.294 1.25649C14.7634 1.6364 14.3651 2.17292 14.155 2.79075L12.6074 7.33752H3.90625C3.28486 7.33822 2.68913 7.58538 2.24974 8.02476C1.81035 8.46415 1.5632 9.05989 1.5625 9.68127V40.15C1.5632 40.7714 1.81035 41.3671 2.24974 41.8065C2.68913 42.2459 3.28486 42.4931 3.90625 42.4938H46.0931C46.7145 42.4931 47.3102 42.2459 47.7496 41.8065C48.189 41.3671 48.4361 40.7714 48.4368 40.15V9.68127C48.4361 9.05989 48.189 8.46415 47.7496 8.02476C47.3102 7.58538 46.7145 7.33822 46.0931 7.33752ZM45.3118 39.3688H4.68818V10.4625H14.8454L17.1134 3.79749H32.8439L35.1594 10.4625H45.3118V39.3688Z"
                  fill="currentColor"
                />
                <path
                  d="M25 12.8063C22.7981 12.8063 20.6457 13.4592 18.8149 14.6825C16.9842 15.9058 15.5572 17.6445 14.7146 19.6787C13.872 21.713 13.6515 23.9514 14.0811 26.111C14.5107 28.2705 15.571 30.2542 17.1279 31.8112C18.6849 33.3681 20.6685 34.4284 22.8281 34.858C24.9877 35.2875 27.2261 35.0671 29.2603 34.2245C31.2946 33.3818 33.0333 31.9549 34.2566 30.1241C35.4799 28.2934 36.1328 26.1409 36.1328 23.9391C36.1295 20.9875 34.9555 18.1578 32.8684 16.0707C30.7813 13.9836 27.9516 12.8096 25 12.8063ZM25 31.9469C23.4162 31.9469 21.868 31.4772 20.5511 30.5973C19.2342 29.7174 18.2078 28.4668 17.6018 27.0035C16.9957 25.5403 16.8371 23.9302 17.1461 22.3768C17.455 20.8235 18.2177 19.3966 19.3376 18.2767C20.4575 17.1568 21.8844 16.3941 23.4378 16.0851C24.9911 15.7762 26.6012 15.9347 28.0645 16.5408C29.5277 17.1469 30.7783 18.1733 31.6583 19.4902C32.5382 20.8071 33.0078 22.3553 33.0078 23.9391C33.0054 26.0622 32.161 28.0976 30.6597 29.5988C29.1585 31.1 27.1231 31.9445 25 31.9469Z"
                  fill="currentColor"
                />
              </svg>
              {t('common.add')}
            </Button>
          </label>
          {dragActive && (
            <div
              id="photo-drag-file-element"
              onDragEnter={handleDrag}
              onDragLeave={handleDrag}
              onDragOver={handleDrag}
              onDrop={handleDrop}
            ></div>
          )}
        </form>
      )}
    </div>
  );
}
