export abstract class ApiService {
  protected apiUrl: string | undefined;

  protected needAuthentication = true;
  auth: any = null;
  accessKey: string = '';

  setApiUrl(apiUrl: string): void {
    this.apiUrl = apiUrl + (apiUrl.endsWith('/') ? '' : '/');
  }

  setAuthInfo(newValue: any): void {
    const authInfo = this.auth.authInfo;
    if (JSON.stringify(authInfo) !== JSON.stringify(newValue)) {
      this.auth.authInfo = newValue;
    }
  }

  protected getHeaders() {
    const headers: { [name: string]: string } = {
      Accept: 'application/json',
    };

    return headers;
  }

  private buildRequest(endpoint: any, method = 'GET', bodyParams?: any) {
    const body =
      bodyParams instanceof FormData
        ? bodyParams
        : bodyParams
          ? JSON.stringify(bodyParams)
          : undefined;
    return fetch(this.apiUrl + endpoint, {
      method,
      headers: this.getHeaders(),
      body,
    });
  }

  protected async makeRequest(endpoint: any, method = 'GET', bodyParams?: any) {
    const data = await this.buildRequest(endpoint, method, bodyParams);
    return await data.json();
  }

  protected async makeRequestBlob(
    endpoint: any,
    method = 'GET',
    bodyParams?: any,
  ) {
    const data = await this.buildRequest(endpoint, method, bodyParams);
    return await data.blob();
  }
}
