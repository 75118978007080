import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { Vehicle } from '../models/Vehicle';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from '../components/VehicleIcon';
import { VehicleShapeSelector } from '../components/VehicleShapeSelector';
import { ConstatUtilsService } from '../services/constat-utils.service';

export default function Step2ChoiceTypeScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const constatUtilsService = new ConstatUtilsService();

  const handleSelect = (shape?: string) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!shape) {
        if (!!newConstat.vehicleB) {
          delete newConstat.vehicleB;
        }
      } else {
        if (!newConstat.vehicleB) {
          newConstat.vehicleB = new Vehicle();
        }
        newConstat.vehicleB.vehicleShape = shape;
        if (
          !constatUtilsService.drivingLicenceNumberRequired(newConstat.vehicleA)
        ) {
          delete newConstat.vehicleB.driverDrivingLicenceNumber;
        }
      }

      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step2-choice-type.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={2}
      extraCssClasses={['screen-step', 'step2-choice-type']}
    >
      <ContentContainer>
        <h3>{t('screens.step2-choice-type.instructions')}</h3>
        <VehicleContainer variant={VehicleContainerVariant.B}>
          <h2>
            <VehicleIcon shape={constat.vehicleB?.vehicleShape}></VehicleIcon>
            {t('screens.step2-choice-type.second-title')}
          </h2>
          <VehicleShapeSelector
            vehicle={new Vehicle(constat.vehicleB)}
            allowNoShape={true}
            onShapeSelected={(shape?: string) => handleSelect(shape)}
          ></VehicleShapeSelector>
        </VehicleContainer>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step1Vehicle)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() =>
              setScreen(
                !constat.vehicleB?.vehicleShape
                  ? Screen.step3Summary
                  : Screen.step2Driver,
              )
            }
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
