import React from 'react';

/**
 * Button properties
 */
type ButtonProps = {
  variant?: ButtonVariant;
  size?: ButtonSize;
  type?: ButtonType;
  isOutlined?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
  onHover?: () => void;
  children: React.ReactNode;
};

/**
 * Button variants
 */
export enum ButtonVariant {
  Primary = 'primary',
  Secondary = 'secondary',
  Tertiary = 'tertiary',
  Green = 'green',
}

/**
 * Button sizes
 */
export enum ButtonSize {
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
}

/**
 * Button types
 */
export enum ButtonType {
  Standard = 'standard',
  Icon = 'icon',
}

/**
 * Button component
 */
export function Button({
  variant = ButtonVariant.Primary,
  size = ButtonSize.Medium,
  type = ButtonType.Standard,
  isOutlined = false,
  isDisabled = false,
  onClick,
  onHover,
  children,
}: ButtonProps): JSX.Element {
  return (
    <button
      className={`btn btn--${variant} btn--${type} btn--${size} ${isOutlined ? 'btn--outline' : ''}`}
      onClick={onClick}
      onMouseEnter={onHover}
      disabled={isDisabled}
    >
      {children}
    </button>
  );
}

type ButtonGroupProps = {
  children: React.ReactNode;
};

export function ButtonGroup({ children }: ButtonGroupProps): JSX.Element {
  return <div className="btn__group">{children}</div>;
}
