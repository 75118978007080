import { AbstractModel } from './Abstract.model';
import { Witness } from './Witness';
import { Wounded } from './Wounded';
import { Vehicle } from './Vehicle';

export class Constat extends AbstractModel {
  /** Copy constructor */
  constructor(fromObj?: object) {
    super();
    Object.assign(this, fromObj);

    if (this.vehicleA) {
      this.vehicleA = new Vehicle(this.vehicleA);
    }

    if (this.vehicleB) {
      this.vehicleB = new Vehicle(this.vehicleB);
    }
  }

  // 1
  dateOfAccident?: string;
  hourOfAccident?: string;

  // 2
  localityCountry?: string;
  localityExactLocation?: string;

  // 4
  propertyDamageOtherThanVehiclesAAndB?: boolean;
  propertyDamageToOtherProperty?: boolean;

  // 5
  witnesses?: Array<Witness>;

  // 6 -> 12
  vehicleA?: Vehicle;
  vehicleB?: Vehicle;

  // 13
  sketchNo?: string;
  sketchRef?: string;

  // Wounded page
  wounded?: Array<Wounded>;

  // Liability page
  irsaScale?: string;

  // Pictures
  situationalPicture?: string;
  overallPicture?: string;
  otherPictures?: Array<string>;

  // Coordinates
  latitude?: number;
  longitude?: number;

  public pushWitness(index: number, witness: Witness): this {
    if (!this.witnesses) {
      this.witnesses = new Array<Witness>();
    }
    if (index > -1 && this.witnesses.at(index)) {
      this.witnesses[index] = witness;
    } else {
      this.witnesses.push(witness);
    }

    return this;
  }

  public pushWounded(index: number, wounded: Wounded): this {
    if (!this.wounded) {
      this.wounded = new Array<Wounded>();
    }
    if (index > -1 && this.wounded.at(index)) {
      this.wounded[index] = wounded;
    } else {
      this.wounded.push(wounded);
    }

    return this;
  }

  public removeWitness(index: number): this {
    if (this.witnesses?.length && index > -1 && index < this.witnesses.length) {
      this.witnesses.splice(index, 1);
    }

    return this;
  }

  public removeWounded(index: number): this {
    if (this.wounded?.length && index > -1 && index < this.wounded.length) {
      this.wounded.splice(index, 1);
    }

    return this;
  }
}
