import React from 'react';

type IllustrationContainerProps = {
  withBorder?: boolean;
  children: React.ReactNode;
  extraClassName?: string;
};

function IllustrationContainer({
  withBorder,
  children,
  extraClassName,
}: IllustrationContainerProps): JSX.Element {
  return (
    <div
      className={`illustration${withBorder ? ' illustration--bordered' : ''}${extraClassName ? ' ' + extraClassName : ''}`}
    >
      {children}
    </div>
  );
}

type IllustrationImageProps = {
  src: string;
  alt: string;
  extraClassName?: string;
};

function IllustrationImage({
  src,
  alt,
  extraClassName,
}: IllustrationImageProps): JSX.Element {
  return (
    <img
      className={`illustration__image${extraClassName ? ' ' + extraClassName : ''}`}
      src={src}
      alt={alt}
    />
  );
}

function IllustrationLoader({ src, alt }: IllustrationImageProps): JSX.Element {
  return <img className="illustration__loader" src={src} alt={alt} />;
}

type IllustrationTitleProps = {
  children: React.ReactNode;
};

function IllustrationTitle({ children }: IllustrationTitleProps): JSX.Element {
  return <h2 className="illustration__title">{children}</h2>;
}

type IllustrationTextProps = {
  children: React.ReactNode;
};

function IllustrationText({ children }: IllustrationTextProps): JSX.Element {
  return <p className="illustration__text">{children}</p>;
}

const Illustration = {
  Container: IllustrationContainer,
  Image: IllustrationImage,
  Loader: IllustrationLoader,
  Title: IllustrationTitle,
  Text: IllustrationText,
};

export default Illustration;
