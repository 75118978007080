import React from 'react';

type ActionsContainerProps = {
  children: React.ReactNode;
};

function ActionsContainer({ children }: ActionsContainerProps): JSX.Element {
  return <section className="actions">{children}</section>;
}

export default ActionsContainer;
