import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from '../components/VehicleIcon';
import { Vehicle } from '../models/Vehicle';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { VehicleShapeSelector } from '../components/VehicleShapeSelector';
import { ConstatUtilsService } from '../services/constat-utils.service';

export default function Step1ChoiceTypeScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const constatUtilsService = new ConstatUtilsService();

  const handleSelect = (shape?: string) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleA) {
        newConstat.vehicleA = new Vehicle();
      }
      newConstat.vehicleA.vehicleShape = shape;
      if (
        !constatUtilsService.drivingLicenceNumberRequired(newConstat.vehicleA)
      ) {
        delete newConstat.vehicleA.driverDrivingLicenceNumber;
      }
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step1-choice-type.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={1}
      extraCssClasses={['screen-step', 'step1-choice-type']}
    >
      <ContentContainer>
        <h3>{t('screens.step1-choice-type.instructions')}</h3>
        <VehicleContainer variant={VehicleContainerVariant.A}>
          <h2>
            <VehicleIcon shape={constat.vehicleA?.vehicleShape}></VehicleIcon>
            {t('screens.step1-choice-type.second-title')}
          </h2>
          <VehicleShapeSelector
            vehicle={new Vehicle(constat.vehicleA)}
            onShapeSelected={(shape?: string) => handleSelect(shape)}
          ></VehicleShapeSelector>
        </VehicleContainer>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() =>
              setScreen(
                constatUtilsService.isVehicleComplete(
                  new Vehicle(constat.vehicleA),
                )
                  ? Screen.step1Summary
                  : Screen.woundedList,
              )
            }
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={!constat.vehicleA?.vehicleShape}
            onClick={() => setScreen(Screen.step1Driver)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
