import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { PhotosUploader } from '../components/PhotosUploader';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';

export default function Step4PhotosScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const handlePhotosChange = (photos: string[]) =>
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      newConstat.otherPictures = photos;
      return newConstat;
    });

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step4-photos.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={4}
      extraCssClasses={['screen-step', 'step4-photos']}
    >
      <ContentContainer>
        <PhotosUploader
          id="constat-other-photos"
          name="constat-other-photos"
          photos={constat.otherPictures ?? []}
          onPhotosChange={handlePhotosChange}
          multiple={true}
        ></PhotosUploader>
        {!(constat.otherPictures ?? []).length && (
          <div className="instructions">
            <Trans i18nKey={'screens.step4-photos.instructions'}>
              <strong></strong>
              <em></em>
            </Trans>
          </div>
        )}
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step3Summary)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.step5InitialImpactA)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
