import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ActionsContainer from '../partials/ActionsContainer';
import List from '../components/List';
import Illustration from '../components/Illustration';
import TitleImage from '../assets/images/content/comment-ca-marche.png';
import EuropeImage from '../assets/images/content/Icone-europe.png';
import ContentContainer from '../partials/ContentContainer';
import ScreenContainer from '../partials/ScreenContainer';

export default function HowThatWorksScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer title={t('screens.how-that-works.title')}>
      <ContentContainer>
        <Illustration.Container withBorder>
          <Illustration.Image
            src={TitleImage}
            alt={t('screens.how-that-works.illustrations.2drivers.alt')}
          />
          <Illustration.Title>
            {t('screens.how-that-works.illustrations.2drivers.title')}
          </Illustration.Title>
        </Illustration.Container>

        <List.Ordered>
          <List.Item>
            <Trans i18nKey={'screens.how-that-works.steps.1'}>
              <strong></strong>
            </Trans>
          </List.Item>

          <List.Item>
            <Trans i18nKey={'screens.how-that-works.steps.2'}>
              <strong></strong>
            </Trans>
          </List.Item>

          <List.Item>
            <Trans i18nKey={'screens.how-that-works.steps.3'}>
              <strong></strong>
            </Trans>
          </List.Item>

          <List.Item>
            <Trans i18nKey={'screens.how-that-works.steps.4'}>
              <strong></strong>
            </Trans>
          </List.Item>

          <List.Item>
            <Trans i18nKey={'screens.how-that-works.steps.5'}>
              <strong></strong>
            </Trans>
          </List.Item>
        </List.Ordered>

        <Illustration.Container>
          <Illustration.Image
            src={EuropeImage}
            alt={t('screens.how-that-works.illustrations.europe.alt')}
          />
          <Illustration.Text>
            {t('screens.how-that-works.illustrations.europe.text')}
          </Illustration.Text>
        </Illustration.Container>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.checkboxes)}
          >
            {t('screens.how-that-works.cta')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
