export enum Screen {
  start,
  howThatWorks,
  checkboxes,
  cgu,
  gdpr,
  cookies,
  witnesses,
  witnessesCrud,
  woundedList,
  woundedCrud,
  step1Summary,
  step1ChoiceType,
  step1Driver,
  step1Insured,
  step1Vehicle,
  step2ChoiceType,
  step2Driver,
  step2Insured,
  step2Vehicle,
  step3Summary,
  step4Photos,
  step5InitialImpactA,
  step6DamagesA,
  step7InitialImpactB,
  step8DamagesB,
  step9Chatbot,
  step10SketchComments,
  step11Localisation,
  step12ConstatPreview,
  signature,
  greetings,
}
