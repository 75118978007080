import React from 'react';
import { useTranslation } from 'react-i18next';

type FooterProps = {
  children?: React.ReactNode;
};

function Footer({ children }: FooterProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <footer className="footer">
      {!children && (
        <section className="footer__content">
          <h3 className="footer__title">{t('partials.footer.title')}</h3>
          <p className="footer__text">{t('partials.footer.text')}</p>
          <a className="footer__phone" href="tel:0473230228">
            04 73 23 02 28
          </a>
          <a className="footer__website" href="https://www.izzyconstat.fr">
            www.izzyconstat.fr
          </a>
        </section>
      )}
      {children}
    </footer>
  );
}

export default Footer;
