import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import WarningImage from '../assets/images/content/warning.png';
import Illustration from '../components/Illustration';
import { InternalApiService } from '../services/internal.api.service';
import loadingImage from '../assets/images/loading.svg';

export default function SignatureScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat] = useLocalStorage('constat', new Constat());
  const [loading, setLoading] = React.useState(false);

  const submit = () => {
    const internalApiService = new InternalApiService();
    internalApiService.submit(constat, setLoading).then((response) => {
      if (false !== response) {
        setScreen(Screen.greetings);
      }
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.signature.title'}>
          <strong></strong>
        </Trans>
      }
      extraCssClasses={['signature']}
    >
      <ContentContainer>
        <h3>
          <Trans i18nKey={'screens.signature.second-title'}></Trans>
        </h3>
        <div className={'signature-info'}>
          <div className={'svg-container'}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.8438 0.625H5.15625C4.82484 0.625372 4.50712 0.757188 4.27278 0.991528C4.03844 1.22587 3.90662 1.54359 3.90625 1.875V18.125C3.90662 18.4564 4.03844 18.7741 4.27278 19.0085C4.50712 19.2428 4.82484 19.3746 5.15625 19.375H14.8438C15.1752 19.3746 15.4929 19.2428 15.7272 19.0085C15.9616 18.7741 16.0934 18.4564 16.0938 18.125V1.875C16.0934 1.54359 15.9616 1.22587 15.7272 0.991528C15.4929 0.757188 15.1752 0.625372 14.8438 0.625ZM14.8438 1.875V3.125H5.15625V1.875H14.8438ZM14.8438 4.375L14.8442 13.125H5.15625V4.375H14.8438ZM14.8438 18.125H5.15625V14.375H14.8444V18.125H14.8438Z"
                fill="currentColor"
              />
              <path
                d="M9.375 15.625H10.625V16.875H9.375V15.625Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <p>
            <Trans i18nKey={'screens.signature.info1'}>
              <strong></strong>
              <strong></strong>
              <strong></strong>
            </Trans>
          </p>
        </div>
        <div className={'signature-info'}>
          <div className={'svg-container'}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 7.8125V5.625C15 4.29892 14.4732 3.02715 13.5355 2.08947C12.5979 1.15178 11.3261 0.625 10 0.625C8.67392 0.625 7.40215 1.15178 6.46447 2.08947C5.52678 3.02715 5 4.29892 5 5.625V7.8125H3.4375V12.8125C3.4375 16.4311 6.38141 19.375 10 19.375C13.6186 19.375 16.5625 16.4311 16.5625 12.8125V7.8125H15ZM6.25 5.625C6.25 4.63044 6.64509 3.67661 7.34835 2.97335C8.05161 2.27009 9.00544 1.875 10 1.875C10.9946 1.875 11.9484 2.27009 12.6517 2.97335C13.3549 3.67661 13.75 4.63044 13.75 5.625V7.8125H6.25V5.625ZM15.3125 12.8125C15.3125 15.7418 12.9293 18.125 10 18.125C7.0707 18.125 4.6875 15.7418 4.6875 12.8125V9.0625H15.3125V12.8125Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <p>
            <Trans i18nKey={'screens.signature.info2'}>
              <strong></strong>
            </Trans>
          </p>
        </div>
        <div className={'signature-info'}>
          <div className={'svg-container'}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 2V17H19.75V2H1ZM9.625 10.9688C9.84179 11.13 10.1048 11.2171 10.375 11.2171C10.6452 11.2171 10.9082 11.13 11.125 10.9688L12.0417 10.2812L18.5 15.125V15.75H2.25V15.125L8.70832 10.2812L9.625 10.9688ZM10.375 9.96875L2.25 3.875V3.25H18.5V3.875L10.375 9.96875ZM2.25 5.4375L7.66668 9.5L2.25 13.5625V5.4375ZM18.5 13.5625L13.0833 9.5L18.5 5.4375V13.5625Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <p>
            <Trans i18nKey={'screens.signature.info3'}>
              <strong></strong>
            </Trans>
          </p>
        </div>
        {!!constat.vehicleB && (
          <>
            <div className={'signature-info vehicle-b'}>
              <Illustration.Image
                src={WarningImage}
                alt=""
              ></Illustration.Image>
              <p>
                <Trans i18nKey={'screens.signature.info-driver-b'}>
                  <strong></strong>
                  <strong></strong>
                </Trans>
              </p>
            </div>
            <em className={'vehicle-b-precision'}>
              <Trans i18nKey={'screens.signature.info-driver-b-aster'}></Trans>
            </em>
          </>
        )}
      </ContentContainer>

      <ActionsContainer>
        {loading ? (
          <Illustration.Loader src={loadingImage} alt=""></Illustration.Loader>
        ) : (
          <ButtonGroup>
            <Button
              variant={ButtonVariant.Green}
              isOutlined
              onClick={() => setScreen(Screen.step12ConstatPreview)}
            >
              {t('common.previous')}
            </Button>

            <Button variant={ButtonVariant.Green} onClick={submit}>
              {t('common.next')}
            </Button>
          </ButtonGroup>
        )}
      </ActionsContainer>
    </ScreenContainer>
  );
}
