import { AbstractModel } from './Abstract.model';

export class Wounded extends AbstractModel {
  /** Copy constructor */
  constructor(fromObj?: object) {
    super();
    Object.assign(this, fromObj);
  }

  name?: string;
  firstname?: string;
  address?: string;
  phone?: string;
  email?: string;
  positionAtTheTimeOfTheAccident?: string;
  natureAndExtentOfWounds?: string;
}
