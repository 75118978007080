import React from 'react';

type UnorderedListProps = {
  children: React.ReactNode;
};

function UnorderedList({ children }: UnorderedListProps): JSX.Element {
  return <ul className="list list--unordered">{children}</ul>;
}

type OrderedListProps = {
  children: React.ReactNode;
};

function OrderedList({ children }: OrderedListProps): JSX.Element {
  return <ol className="list list--ordered">{children}</ol>;
}

type ListItemProps = {
  children: React.ReactNode;
};

function ListItem({ children }: ListItemProps): JSX.Element {
  return (
    <li className="list__item">
      <div className="list__item-content">{children}</div>
    </li>
  );
}

const List = {
  Unordered: UnorderedList,
  Ordered: OrderedList,
  Item: ListItem,
};

export default List;
