import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import config from '../config';
import Illustration from '../components/Illustration';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import DriverImage from '../assets/images/content/person.png';
import { Textarea } from '../components/Textarea';

export default function Step10SketchCommentsScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const handleComment = (
    vehicleVariant: VehicleContainerVariant,
    value: string,
  ) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (
        VehicleContainerVariant.A === vehicleVariant &&
        !!newConstat.vehicleA
      ) {
        newConstat.vehicleA.comments = value;
      } else if (
        VehicleContainerVariant.B === vehicleVariant &&
        !!newConstat.vehicleB
      ) {
        newConstat.vehicleB.comments = value;
      }

      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step10-sketch-comments.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={10}
      extraCssClasses={['screen-step', 'step10-sketch-comments']}
    >
      <ContentContainer>
        {!!constat.sketchRef && (
          <Illustration.Image
            src={
              config.INTERNAL_API_URL +
              '/chatbot/img/croquis_AB/' +
              constat.sketchRef
            }
            alt={constat.sketchNo ?? ''}
            extraClassName={'sketch'}
          ></Illustration.Image>
        )}
        <VehicleContainer variant={VehicleContainerVariant.A}>
          <h2>
            <Illustration.Image src={DriverImage} alt=""></Illustration.Image>
            {t('screens.step10-sketch-comments.driver-a-title')}
          </h2>
        </VehicleContainer>
        <Textarea
          label={t('screens.step10-sketch-comments.driver-a-instructions')}
          name="comments_a"
          id="comments_a"
          value={constat.vehicleA?.comments || ''}
          onChange={(value) => handleComment(VehicleContainerVariant.A, value)}
        />
        {!!constat.vehicleB && (
          <>
            <VehicleContainer variant={VehicleContainerVariant.B}>
              <h2>
                <Illustration.Image
                  src={DriverImage}
                  alt=""
                ></Illustration.Image>
                {t('screens.step10-sketch-comments.driver-b-title')}
              </h2>
            </VehicleContainer>
            <Textarea
              label={t('screens.step10-sketch-comments.driver-b-instructions')}
              name="comments_b"
              id="comments_b"
              value={constat.vehicleB?.comments || ''}
              onChange={(value) =>
                handleComment(VehicleContainerVariant.B, value)
              }
            />
          </>
        )}
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step9Chatbot)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.step11Localisation)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
