import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { ConstatUtilsService } from '../services/constat-utils.service';
import { Vehicle } from '../models/Vehicle';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from '../components/VehicleIcon';
import { Form } from '../components/Form';
import { InputField, InputFieldType } from '../components/InputField';

export default function Step2VehicleScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());
  const constatUtilsService = new ConstatUtilsService();

  const handleChange = (fieldName: string, value: any) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleB) {
        newConstat.vehicleB = new Vehicle();
      }
      newConstat.vehicleB.setField(fieldName, value);
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step2-vehicle.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={2}
      extraCssClasses={['screen-step', 'step2-vehicle']}
    >
      <ContentContainer>
        <h3>{t('screens.step2-vehicle.instructions')}</h3>
        <VehicleContainer variant={VehicleContainerVariant.B}>
          <h2>
            <VehicleIcon shape={constat.vehicleB?.vehicleShape}></VehicleIcon>
            {t('screens.step2-vehicle.second-title')}
          </h2>
        </VehicleContainer>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <InputField
            name="vehicleRegistrationNumber"
            id="vehicleRegistrationNumber"
            type={InputFieldType.Text}
            required={constatUtilsService.vehicleRegistrationNumberRequired(
              constat.vehicleB,
            )}
            label={t('vehicle.label.vehicleRegistrationNumber')}
            value={constat.vehicleB?.vehicleRegistrationNumber || ''}
            onChange={(value) =>
              handleChange('vehicleRegistrationNumber', value)
            }
          />
          <InputField
            name="trailerRegistrationNumber"
            id="trailerRegistrationNumber"
            type={InputFieldType.Text}
            required={false}
            label={t('vehicle.label.trailerRegistrationNumber')}
            value={constat.vehicleB?.trailerRegistrationNumber || ''}
            onChange={(value) =>
              handleChange('trailerRegistrationNumber', value)
            }
          />
          <InputField
            name="insuranceCompanyName"
            id="insuranceCompanyName"
            type={InputFieldType.Text}
            required={true}
            label={t('vehicle.label.insuranceCompanyName')}
            value={constat.vehicleB?.insuranceCompanyName || ''}
            onChange={(value) => handleChange('insuranceCompanyName', value)}
          />
          <InputField
            name="insuranceCompanyPolicyNumber"
            id="insuranceCompanyPolicyNumber"
            type={InputFieldType.Text}
            required={true}
            label={t('vehicle.label.insuranceCompanyPolicyNumber')}
            value={constat.vehicleB?.insuranceCompanyPolicyNumber || ''}
            onChange={(value) =>
              handleChange('insuranceCompanyPolicyNumber', value)
            }
          />
        </Form>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step2Driver)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={
              !constatUtilsService.isVehicleLastDataComplete(
                new Vehicle(constat.vehicleB),
              )
            }
            onClick={() => setScreen(Screen.step3Summary)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
