import { Wounded } from '../models/Wounded';
import React from 'react';
import { Button, ButtonVariant } from './Button';
import { useTranslation } from 'react-i18next';
import WoundedImage from '../assets/images/content/wounded.png';
import { Screen } from '../enums/screen.enum';
import { useLocalStorage } from '@uidotdev/usehooks';
import { WoundedContext } from 'contexts/WoundedContext';
import { Constat } from 'models/Constat';

type WoundedBoxProps = {
  wounded?: Wounded;
  index?: number;
};

export function WoundedBox({ wounded, index }: WoundedBoxProps) {
  const { t } = useTranslation();
  const [, setScreen] = useLocalStorage('screen');

  const { dispatch } = React.useContext(WoundedContext);
  const [, setConstat] = useLocalStorage('constat', new Constat());

  const handleAddClick = () => {
    dispatch({ type: 'ADD' });

    setTimeout(() => {
      setScreen(Screen.woundedCrud);
    }, 10);
  };

  const handleEditClick = () => {
    dispatch({ type: 'EDIT', payload: index ?? null });

    setTimeout(() => {
      setScreen(Screen.woundedCrud);
    }, 10);
  };

  const handleRemoveClick = () => {
    setConstat((prevConstat) => {
      return new Constat(prevConstat).removeWounded(index!);
    });
  };

  return (
    <>
      {!wounded && (
        <div className="wounded" data-status="add">
          <Button variant={ButtonVariant.Green} onClick={handleAddClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.1875 9.375H10.625V2.8125H9.375V9.375H2.8125V10.625H9.375V17.1875H10.625V10.625H17.1875V9.375Z"
                fill="currentColor"
              />
            </svg>
            {t('common.add')}
          </Button>

          <img
            className="wounded__image"
            src={WoundedImage}
            alt={t('screens.wounded.illustrations.wounded.alt')}
          />
        </div>
      )}

      {wounded && (
        <div className="wounded" data-status="editable">
          <span className="wounded__name">
            {wounded.firstname} {wounded.name?.charAt(0)?.toUpperCase()}.
          </span>

          <Button variant={ButtonVariant.Green} onClick={handleEditClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_112_1819)">
                <path
                  d="M1.15871 18.8379L1.16211 18.8413C1.25222 18.932 1.3594 19.0041 1.47746 19.0533C1.59553 19.1026 1.72216 19.128 1.85007 19.1281C1.95771 19.128 2.0646 19.1102 2.16648 19.0755L7.71359 17.1938L18.3581 6.54928C19.0088 5.89849 19.3744 5.01586 19.3744 4.09555C19.3743 3.17524 19.0087 2.29264 18.3579 1.64191C17.7071 0.991184 16.8245 0.625631 15.9042 0.625671C14.9839 0.625712 14.1013 0.991342 13.4505 1.64213L2.80601 12.2867L0.924449 17.8336C0.86518 18.0061 0.855719 18.1918 0.897152 18.3694C0.938585 18.5471 1.02923 18.7094 1.15871 18.8379ZM14.3345 2.52599C14.7514 2.11229 15.3152 1.88065 15.9025 1.88177C16.4898 1.8829 17.0528 2.11671 17.468 2.53201C17.8833 2.94731 18.1171 3.51025 18.1182 4.09756C18.1194 4.68487 17.8877 5.24869 17.474 5.66556L16.0742 7.06533L12.9346 3.92576L14.3345 2.52599ZM3.89625 12.9642L12.0508 4.80963L15.1903 7.9492L7.03578 16.1037L2.28445 17.7155L3.89625 12.9642Z"
                  fill="currentColor"
                />
              </g>
              <defs>
                <clipPath id="clip0_112_1819">
                  <rect width="20" height="20" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Button>

          <Button variant={ButtonVariant.Secondary} onClick={handleRemoveClick}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.6919 4.19193L15.808 3.30811L10 9.11615L4.19195 3.30811L3.30804 4.19193L9.11613 10L3.30804 15.8081L4.19195 16.6919L10 10.8839L15.808 16.6919L16.6919 15.8081L10.8839 10L16.6919 4.19193Z"
                fill="currentColor"
              />
            </svg>
          </Button>

          <img
            className="wounded__image"
            src={WoundedImage}
            alt={t('screens.wounded.illustrations.wounded.alt')}
          />
        </div>
      )}
    </>
  );
}

type WoundedListProps = {
  wounded?: Wounded[];
};

export function WoundedList({ wounded }: WoundedListProps) {
  return (
    <div className="wounded__list">
      <WoundedBox />

      {wounded?.map((w, i) => <WoundedBox key={i} index={i} wounded={w} />)}
    </div>
  );
}
