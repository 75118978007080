import config from '../config';
import { ApiService } from './api.service';
import { Constat } from '../models/Constat';
import React from 'react';
import { Vehicle } from '../models/Vehicle';
import DefaultPartnerImage from '../assets/images/brand/Logo.svg';

export class InternalApiService extends ApiService {
  constructor() {
    super();
    this.setApiUrl(config.INTERNAL_API_URL);
    this.needAuthentication = false;
  }

  isAlive = () =>
    this.makeRequest('index.php', 'GET')
      .then(() => true)
      .catch(() => false);

  loadVehicleData(
    vehicleUuid: string,
    setConstat: React.Dispatch<React.SetStateAction<Constat>>,
    setPartnerImage: React.Dispatch<React.SetStateAction<string>>,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setLoading(true);
    setConstat(new Constat());
    setPartnerImage(DefaultPartnerImage);
    return this.makeRequest('load-vehicle.php?vehicle=' + vehicleUuid, 'GET')
      .then((fleetVehicle: any) => {
        if (!fleetVehicle) {
          return;
        }
        setConstat(() => {
          const constat = new Constat();
          constat.vehicleA = new Vehicle();
          constat.vehicleA.fleetVehicle = fleetVehicle.uuid ?? '';
          constat.vehicleA.vehicleShape = fleetVehicle.vehicle_shape ?? '';
          constat.vehicleA.vehicleRegistrationNumber =
            fleetVehicle.license_plate ?? '';
          constat.vehicleA.vehicleMake = fleetVehicle.brand ?? '';
          constat.vehicleA.vehicleType = fleetVehicle.model ?? '';
          constat.vehicleA.insuranceCompanyName = fleetVehicle.insurance ?? '';
          constat.vehicleA.insuranceCompanyPolicyNumber =
            fleetVehicle.contract_number ?? '';
          constat.vehicleA.driverFirstname =
            fleetVehicle.driver_firstname ?? '';
          constat.vehicleA.driverName = fleetVehicle.driver_lastname ?? '';
          constat.vehicleA.driverTel = fleetVehicle.driver_tel ?? '';
          constat.vehicleA.driverEmail = fleetVehicle.driver_email ?? '';
          constat.vehicleA.driverAddress = fleetVehicle.driver_address ?? '';
          constat.vehicleA.driverDrivingLicenceNumber =
            fleetVehicle.driver_driving_licence_number ?? '';
          constat.vehicleA.insuredName = fleetVehicle.insured_name ?? '';
          constat.vehicleA.insuredFirstname =
            fleetVehicle.insured_firstname ?? '';
          constat.vehicleA.insuredTel = fleetVehicle.insured_tel ?? '';
          constat.vehicleA.insuredEmail = fleetVehicle.insured_email ?? '';

          return constat;
        });
        setPartnerImage(fleetVehicle.fleet?.owner?.logo ?? null);
      })
      .catch(() => {})
      .finally(() => setLoading(false));
  }

  uploadFile(
    file: File,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setLoading(true);
    const formData = new FormData();
    formData.append('file', file);
    return this.makeRequest('upload.php', 'POST', formData)
      .then((data) => {
        if (data.success && data.path) {
          return data.path;
        }
        console.error('Upload error: ', data);

        return false;
      })
      .catch(() => false)
      .finally(() => setLoading(false));
  }

  preview(
    constat: Constat,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setLoading(true);

    return this.makeRequestBlob('submit.php', 'POST', constat)
      .then((data) => data)
      .catch(() => false)
      .finally(() => setLoading(false));
  }

  submit(
    constat: Constat,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setLoading(true);

    return this.makeRequest('submit.php?signature=1', 'POST', constat)
      .then((data) => data)
      .catch(() => false)
      .finally(() => setLoading(false));
  }
}
