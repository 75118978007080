import React from 'react';

type TextareaProps = {
  defaultValue?: string;
  defaultRows?: number;
  value?: string;
  name?: string;
  id?: string;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  onChange?: (value: string) => void;
};

export function Textarea({
  defaultValue,
  defaultRows = 5,
  value,
  name,
  id,
  required = false,
  disabled = false,
  label,
  onChange,
}: TextareaProps): JSX.Element {
  const [dirty, setDirty] = React.useState(false);
  // const [currentValue, setCurrentValue] = React.useState(defaultValue);
  const textAreaRef = React.useRef<HTMLTextAreaElement>(null);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) {
      onChange(e.target.value);
    }
  };

  return (
    <div className="input input__textarea">
      <label className="input__label" htmlFor={id}>
        {label}
        {label && required ? <em>*</em> : <></>}
      </label>
      <textarea
        className={`input__field${dirty ? ' input__field_dirty' : ''}${!!value ? ' input__field_filled' : ''}`}
        id={id}
        name={name}
        defaultValue={defaultValue}
        value={value}
        disabled={disabled}
        required={required}
        onChange={handleChange}
        onBlur={() => setDirty(true)}
        ref={textAreaRef}
        rows={defaultRows}
      />
    </div>
  );
}
