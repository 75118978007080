import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import commonEN from '../assets/i18n/en.json';
import dataEN from '../assets/i18n/data-en.json';
import commonFR from '../assets/i18n/fr.json';
import dataFR from '../assets/i18n/data-fr.json';

const resources = {
  en: {
    translation: commonEN,
    data: dataEN,
  },
  fr: {
    translation: commonFR,
    data: dataFR,
  },
};

i18next.use(LanguageDetector).use(initReactI18next).init({
  resources,
  lng: 'fr', // default language
  fallbackLng: 'fr', // fallback language
});

export default i18next;
