import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from '../components/VehicleIcon';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { VehicleDamageSelector } from '../components/VehicleDamageSelector';
import { Vehicle } from '../models/Vehicle';

export default function Step5InitialImpactAScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const handleSelect = (impact?: string) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleA) {
        newConstat.vehicleA = new Vehicle();
      }
      newConstat.vehicleA.initialImpact = impact;
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step5-initial-impact-a.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={5}
      extraCssClasses={['screen-step', 'step5-initial-impact-a']}
    >
      <ContentContainer>
        <VehicleContainer variant={VehicleContainerVariant.A}>
          <h2>
            <VehicleIcon shape={constat.vehicleA?.vehicleShape}></VehicleIcon>
            {t('screens.step5-initial-impact-a.second-title')}
          </h2>
        </VehicleContainer>
        <h3>
          <Trans
            i18nKey={'screens.step5-initial-impact-a.instructions'}
          ></Trans>
        </h3>
        <VehicleDamageSelector
          vehicle={new Vehicle(constat.vehicleA)}
          vehicleVariant={VehicleContainerVariant.A}
          onDamageSelected={(impact?: string) => handleSelect(impact)}
        ></VehicleDamageSelector>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step4Photos)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.step6DamagesA)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
