import React from 'react';
import Checkbox from '../components/Checkbox';

type VehicleFieldCheckProps = {
  placeholder: string;
  data?: string;
};

function VehicleFieldCheck({
  placeholder,
  data,
}: VehicleFieldCheckProps): JSX.Element {
  return (
    <div className="vehicle-field-check">
      <Checkbox isChecked={!!data} readonly={true}>
        <span
          className={!data ? 'placeholder' : ''}
          dangerouslySetInnerHTML={{
            __html: data?.length ? data : placeholder,
          }}
        ></span>
      </Checkbox>
    </div>
  );
}

export default VehicleFieldCheck;
