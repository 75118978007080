import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import { messenger } from '../chatbot/main';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import config from '../config';
import Illustration from '../components/Illustration';
import loadingImage from '../assets/images/loading.svg';
import { ConstatUtilsService } from '../services/constat-utils.service';
import { InternalApiService } from '../services/internal.api.service';

type PartialChatBotContexts = {
  user: {
    oneRideDone?: boolean;
    welldone?: string;
    cas_bareme_irsa?: string;
    cases_cochees_A?: string;
    cases_cochees_B?: string;
    fault_A?: string;
    fault_B?: string;
    commentaire_A?: string;
    commentaire_B?: string;
    responsabilite_A?: string;
    responsabilite_B?: string;
    sketchNo?: string;
    sketchRef?: string;
  };
};

type PartialChatBotConfigParameters = {
  constat: Constat;
  personA: string;
  personB: string;
};

export default function Step9ChatbotScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());
  const [chatbotContexts] = useLocalStorage(
    'contexts',
    {} as PartialChatBotContexts,
  );
  const [error, setError] = React.useState(false);

  const loadChatbot = () => {
    setError(false);
    const root = document.querySelector<HTMLElement>(
      '.step9-chatbot .content .chatbot',
    );
    if (isActive && root && (root?.childNodes?.length ?? 0) < 2) {
      const internalApiService = new InternalApiService();

      internalApiService
        .isAlive()
        .then((alive: boolean) => {
          setError(!alive);
          if (alive) {
            const constatUtilsService = new ConstatUtilsService();
            const chatbotParameters: PartialChatBotConfigParameters = {
              constat: constat,
              personA: constatUtilsService.getChatbotPersonA(constat),
              personB: constatUtilsService.getChatbotPersonB(constat),
            };
            messenger.default(
              {
                configFile:
                  config.INTERNAL_API_URL +
                  '/chatbot/config.php?parameters=' +
                  btoa(
                    encodeURIComponent(
                      JSON.stringify(chatbotParameters),
                    ).replace(/%([0-9A-F]{2})/g, (match, p1) =>
                      String.fromCharCode(parseInt(p1, 16)),
                    ),
                  ),
              },
              undefined,
              root,
            );
          }
        })
        .catch(() => setError(true));
    }
  };

  React.useEffect(() => {
    if (isActive) {
      if (!!chatbotContexts?.user?.welldone) {
        setConstat((prevConstat: Constat) => {
          prevConstat.irsaScale = chatbotContexts.user.cas_bareme_irsa ?? '';
          if (prevConstat.vehicleA) {
            prevConstat.vehicleA.circumstances = chatbotContexts.user
              .cases_cochees_A
              ? JSON.parse(chatbotContexts.user.cases_cochees_A)
              : [];
            prevConstat.vehicleA.remarks = chatbotContexts.user.commentaire_A ?? '';
            prevConstat.vehicleA.fault = chatbotContexts.user.fault_A ?? '';
            prevConstat.vehicleA.liability =
              chatbotContexts.user.responsabilite_A ?? '';
          }
          if (prevConstat.vehicleB) {
            prevConstat.vehicleB.circumstances = chatbotContexts.user
              .cases_cochees_B
              ? JSON.parse(chatbotContexts.user.cases_cochees_B)
              : [];
            prevConstat.vehicleB.remarks = chatbotContexts.user.commentaire_B ?? '';
            prevConstat.vehicleB.fault = chatbotContexts.user.fault_B ?? '';
            prevConstat.vehicleB.liability =
              chatbotContexts.user.responsabilite_B ?? '';
          }
          prevConstat.sketchNo = chatbotContexts.user.sketchNo;
          prevConstat.sketchRef = chatbotContexts.user.sketchRef;

          return prevConstat;
        });
      }
    }
  }, [chatbotContexts]);

  React.useEffect(() => {
    loadChatbot();
  }, [isActive]);

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step9-chatbot.title'}>
          <strong></strong>
          <strong></strong>
        </Trans>
      }
      timelineStep={9}
      extraCssClasses={['screen-step', 'step9-chatbot']}
    >
      <ContentContainer>
        <div className={'chatbot'}>
          {error ? (
            <div className={'chatbot-error'}>
              <p>
                <Trans i18nKey={'chatbot.error'}></Trans>
              </p>
              <Button variant={ButtonVariant.Tertiary} onClick={loadChatbot}>
                {t('common.retry')}
              </Button>
            </div>
          ) : (
            <Illustration.Loader
              src={loadingImage}
              alt=""
            ></Illustration.Loader>
          )}
        </div>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step8DamagesB)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={
              !chatbotContexts?.user?.oneRideDone &&
              !chatbotContexts?.user?.welldone
            }
            onClick={() => setScreen(Screen.step10SketchComments)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
