import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../partials/ScreenContainer';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';

export default function GDPRScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer title={t('screens.gdpr.title')}>
      <ContentContainer>
        <div
          dangerouslySetInnerHTML={{
            __html: t('data:gdpr'),
          }}
        ></div>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.checkboxes)}
          >
            {t('common.back')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
