import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { Vehicle } from '../models/Vehicle';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from '../components/VehicleIcon';
import { VehicleDamageSelector } from '../components/VehicleDamageSelector';

export default function Step7InitialImpactBScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const handleSelect = (impact?: string) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleB) {
        newConstat.vehicleB = new Vehicle();
      }
      newConstat.vehicleB.initialImpact = impact;
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step7-initial-impact-b.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={7}
      extraCssClasses={['screen-step', 'step7-initial-impact-b']}
    >
      <ContentContainer>
        <VehicleContainer variant={VehicleContainerVariant.B}>
          <h2>
            <VehicleIcon shape={constat.vehicleB?.vehicleShape}></VehicleIcon>
            {t('screens.step7-initial-impact-b.second-title')}
          </h2>
        </VehicleContainer>
        <h3>
          <Trans
            i18nKey={'screens.step7-initial-impact-b.instructions'}
          ></Trans>
        </h3>
        <VehicleDamageSelector
          vehicle={new Vehicle(constat.vehicleB)}
          vehicleVariant={VehicleContainerVariant.B}
          onDamageSelected={(impact?: string) => handleSelect(impact)}
        ></VehicleDamageSelector>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step6DamagesA)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.step8DamagesB)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
