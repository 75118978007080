import config from '../config';
import { ApiService } from './api.service';
import React from 'react';

export class NominatimApiService extends ApiService {
  constructor() {
    super();
    this.setApiUrl(config.NOMINATIM_API_URL);
    this.needAuthentication = false;
  }

  reverse(
    latitude: string,
    longitude: string,
    setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  ) {
    setLoading(true);
    return this.makeRequest(
      `reverse?lat=${latitude}&lon=${longitude}&format=json`,
      'GET',
    )
      .then((data: any) => data)
      .catch(() => false)
      .finally(() => setLoading(false));
  }
}
