import React from 'react';
import TimelineStep from '../partials/TimelineStep';

type ScreenContainerProps = {
  title?: string | React.ReactElement;
  timelineStep?: number;
  children: React.ReactNode;
  extraCssClasses?: string[];
};

function ScreenContainer({
  title,
  timelineStep,
  children,
  extraCssClasses,
}: ScreenContainerProps): JSX.Element {
  return (
    <main
      className={
        `screen` + (extraCssClasses ? ' ' + extraCssClasses.join(' ') : '')
      }
    >
      {title && (
        <section className="screen__title">
          <h1>{title}</h1>
          {timelineStep && <TimelineStep step={timelineStep} />}
        </section>
      )}
      <section className="screen__content">{children}</section>
    </main>
  );
}

export default ScreenContainer;
