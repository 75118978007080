import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { Document, Page, pdfjs } from 'react-pdf';
import { InternalApiService } from '../services/internal.api.service';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import loadingImage from '../assets/images/loading.svg';
import Illustration from '../components/Illustration';

// Hard copy worker from node_modules/react-pdf/node_modules/pdfjs-dist/build/pdf.worker.js
pdfjs.GlobalWorkerOptions.workerSrc = `pdf.worker.min.js`;
// Or a CDN
//pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export default function Step12ConstatPreviewScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat] = useLocalStorage('constat', new Constat());
  const [loading, setLoading] = React.useState(false);
  const [pdfContent, setPdfContent] = React.useState<string>();
  const [numPages, setNumPages] = React.useState<number>();

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }): void =>
    setNumPages(numPages);

  const loadPDF = () => {
    const internalApiService = new InternalApiService();
    internalApiService
      .preview(constat, setLoading)
      .then((pdfBlob: boolean | Blob) => {
        if (pdfBlob instanceof Blob) {
          let reader = new FileReader();
          reader.readAsDataURL(pdfBlob);
          reader.onloadend = () => {
            const base64String = reader.result as string;
            setPdfContent(base64String.substr(base64String.indexOf(',') + 1));
          };
        }
      });
  };

  React.useEffect(() => {
    if (isActive) {
      loadPDF();
    }
  }, [isActive]);

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step12-constat-preview.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={12}
      extraCssClasses={['screen-step', 'step12-constat-preview']}
    >
      <ContentContainer>
        {loading && (
          <Illustration.Loader src={loadingImage} alt=""></Illustration.Loader>
        )}
        {pdfContent && (
          <Document
            file={`data:application/pdf;base64,${pdfContent}`}
            onLoadSuccess={onDocumentLoadSuccess}
            loading={
              <p>
                <Trans i18nKey={'pdf.loading'}></Trans>
              </p>
            }
            error={
              <>
                <p>
                  <Trans i18nKey={'pdf.error'}></Trans>
                </p>
                <Button variant={ButtonVariant.Tertiary} onClick={loadPDF}>
                  {t('common.retry')}
                </Button>
              </>
            }
            noData={
              <>
                <p>
                  <Trans i18nKey={'pdf.error'}></Trans>
                </p>
                <Button variant={ButtonVariant.Tertiary} onClick={loadPDF}>
                  {t('common.retry')}
                </Button>
              </>
            }
          >
            {Array.from(Array(numPages).keys())
              .map((x, i) => i + 1)
              .map((page) => (
                <Page key={page} pageNumber={page} width={350} />
              ))}
          </Document>
        )}
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step11Localisation)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={!pdfContent}
            onClick={() => setScreen(Screen.signature)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
