import React from 'react';

type CheckboxProps = {
  isChecked?: boolean;
  readonly?: boolean;
  name?: string;
  id?: string;
  children: React.ReactNode;
  onChange?: (checked: boolean) => void;
};

function Checkbox({
  isChecked = false,
  readonly = false,
  children,
  id,
  name,
  onChange,
}: CheckboxProps): JSX.Element {
  const [checked, setChecked] = React.useState(isChecked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }

    setChecked(e.target.checked);
  };

  React.useEffect(() => {
    setChecked(isChecked);
  }, [isChecked]);

  return (
    <div className="input">
      <label htmlFor={id} className="input__checkbox">
        <input
          className="input__field"
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={readonly}
          onChange={handleChange}
        />

        <span className="input__checkbox-indicator">
          {checked && (
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.82649 14.9324L2.38501 9.831L3.23993 8.91904L7.79845 13.1926L16.7455 4.24561L17.6294 5.12943L7.82649 14.9324Z"
                fill="currentColor"
              />
            </svg>
          )}
        </span>

        <span className="input__label">{children}</span>
      </label>
    </div>
  );
}

export default Checkbox;
