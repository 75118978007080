import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../partials/ScreenContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ContentContainer from '../partials/ContentContainer';
import { InputField, InputFieldType } from '../components/InputField';
import { Form } from '../components/Form';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Wounded } from 'models/Wounded';
import { Constat } from 'models/Constat';
import { WoundedContext } from 'contexts/WoundedContext';
import HeaderImage from '../assets/images/content/blesses-header.png';
import Illustration from '../components/Illustration';
import { ConstatUtilsService } from '../services/constat-utils.service';

export default function WoundedCrudScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const constatUtilsService = new ConstatUtilsService();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const { state, dispatch } = React.useContext(WoundedContext);

  const currentWoundedIndex =
    state.woundedIndexToEdit !== null ? state.woundedIndexToEdit : -1;

  const [wounded, setWounded] = React.useState<Wounded>(
    currentWoundedIndex >= 0
      ? constat.wounded?.[currentWoundedIndex] || new Wounded()
      : new Wounded(),
  );
  const [emailValid, setEmailValid] = React.useState(
    !wounded?.email || constatUtilsService.validEmail(wounded?.email),
  );
  const [phoneValid, setPhoneValid] = React.useState(
    !wounded?.phone || constatUtilsService.validPhone(wounded?.phone),
  );

  const currentWoundedDisplayedIndex =
    (-1 !== currentWoundedIndex
      ? currentWoundedIndex
      : constat.wounded?.length ?? 0) + 1;

  React.useEffect(() => {
    if (state.woundedIndexToEdit !== null) {
      selectWounded(state.woundedIndexToEdit);
    } else {
      setWounded(new Wounded());
    }
  }, [state.woundedIndexToEdit, isActive]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const selectWounded = (index: number) => {
    dispatch({ type: 'EDIT', payload: index });
    let currentWounded = constat.wounded?.[index];
    setWounded(currentWounded ? currentWounded : new Wounded());
  };

  const saveWounded = () => {
    setConstat((prevConstat) => {
      return new Constat(prevConstat).pushWounded(currentWoundedIndex, wounded);
    });
    setScreen(Screen.woundedList);
  };

  const handleChange = (fieldName: string, value: any) => {
    setWounded((prevWounded) => {
      let newWounded = new Wounded(prevWounded);
      newWounded.setField(fieldName, value);
      return newWounded;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Illustration.Image
          src={HeaderImage}
          alt={t('screens.wounded-crud.title', {
            num: currentWoundedDisplayedIndex,
          })}
        />
      }
    >
      <ContentContainer>
        <h2>
          {t('screens.wounded-crud.title', {
            num: currentWoundedDisplayedIndex,
          })}
        </h2>
        <Form onSubmit={handleSubmit}>
          <InputField
            type={InputFieldType.Text}
            name="name"
            id="name"
            label={t('screens.wounded-crud.form.name')}
            value={wounded?.name || ''}
            onChange={(value) => handleChange('name', value)}
          />

          <InputField
            type={InputFieldType.Text}
            name="firstname"
            id="firstname"
            label={t('screens.wounded-crud.form.firstname')}
            value={wounded?.firstname || ''}
            onChange={(value) => handleChange('firstname', value)}
          />

          <InputField
            type={InputFieldType.Text}
            name="phone"
            id="phone"
            label={t('screens.wounded-crud.form.phone')}
            value={wounded?.phone || ''}
            setValid={setPhoneValid}
            validateWith={constatUtilsService.validPhone}
            onChange={(value) => handleChange('phone', value)}
          />

          <InputField
            type={InputFieldType.Text}
            name="email"
            id="email"
            label={t('screens.wounded-crud.form.email')}
            value={wounded?.email || ''}
            setValid={setEmailValid}
            validateWith={constatUtilsService.validEmail}
            onChange={(value) => handleChange('email', value)}
          />

          <InputField
            type={InputFieldType.GooglePlaces}
            name="address"
            id="address"
            label={t('screens.wounded-crud.form.address')}
            value={wounded?.address || ''}
            onChange={(value) => handleChange('address', value)}
          />

          <InputField
            type={InputFieldType.Text}
            name="positionAtTheTimeOfTheAccident"
            id="positionAtTheTimeOfTheAccident"
            label={t(
              'screens.wounded-crud.form.positionAtTheTimeOfTheAccident',
            )}
            value={wounded?.positionAtTheTimeOfTheAccident || ''}
            onChange={(value) =>
              handleChange('positionAtTheTimeOfTheAccident', value)
            }
          />

          <InputField
            type={InputFieldType.Text}
            name="natureAndExtentOfWounds"
            id="natureAndExtentOfWounds"
            label={t('screens.wounded-crud.form.natureAndExtentOfWounds')}
            value={wounded?.natureAndExtentOfWounds || ''}
            onChange={(value) => handleChange('natureAndExtentOfWounds', value)}
          />
        </Form>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.woundedList)}
          >
            {t('common.cancel')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={!emailValid || !phoneValid}
            onClick={saveWounded}
          >
            {t(currentWoundedIndex > -1 ? 'common.save' : 'common.add')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
