export const woundedInitialState = {
  woundedIndexToEdit: null,
  buttonClicked: false,
};

export const woundedReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'ADD':
      return { ...state, woundedIndexToEdit: null, buttonClicked: true };
    case 'EDIT':
      return {
        ...state,
        woundedIndexToEdit: action.payload,
        buttonClicked: true,
      };
    case 'REMOVE':
      return { ...state, woundedIndexToEdit: null, buttonClicked: true };
    default:
      return state;
  }
};
