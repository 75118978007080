import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation } from 'react-i18next';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import Illustration from '../components/Illustration';
import ContentContainer from '../partials/ContentContainer';
import ScreenContainer from '../partials/ScreenContainer';
import Footer from '../partials/Footer';
import { useLocalStorage } from '@uidotdev/usehooks';
import loadingImage from '../assets/images/loading.svg';
import { Constat } from '../models/Constat';
import StartImage from '../assets/images/content/Illustration-accueil.png';
import config from '../config';

export default function StartScreen({
  isActive,
  loading,
  setScreen,
}: {
  isActive: boolean;
  loading: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const [constat] = useLocalStorage('constat', new Constat());

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer extraCssClasses={['start']}>
      <ContentContainer>
        <Illustration.Container extraClassName={'illustration_start'}>
          <Illustration.Image src={StartImage} alt=""></Illustration.Image>
        </Illustration.Container>

        {loading ? (
          <Illustration.Container>
            <Illustration.Loader
              src={loadingImage}
              alt=""
            ></Illustration.Loader>
          </Illustration.Container>
        ) : (
          <>
            {constat?.vehicleA?.vehicleRegistrationNumber && (
              <div className={`registration`}>
                {t('screens.start.vehicle', {
                  vehicle: constat.vehicleA.vehicleRegistrationNumber,
                })}
              </div>
            )}

            <ButtonGroup>
              <Button
                variant={ButtonVariant.Green}
                onClick={() => setScreen(Screen.howThatWorks)}
              >
                {t('screens.start.cta.start')}
              </Button>
              {!constat?.vehicleA?.vehicleRegistrationNumber && (
                <Button
                  variant={ButtonVariant.Green}
                  onClick={() => (window.location = config.CHATBOT_URL)}
                  isOutlined
                >
                  {t('screens.start.cta.start-chatbot')}
                </Button>
              )}
            </ButtonGroup>
          </>
        )}
      </ContentContainer>
      <Footer />
    </ScreenContainer>
  );
}
