import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation } from 'react-i18next';
import ScreenContainer from '../partials/ScreenContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ContentContainer from '../partials/ContentContainer';
import { InputField, InputFieldType } from '../components/InputField';
import { Form } from '../components/Form';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Witness } from 'models/Witness';
import { Constat } from 'models/Constat';
import { WitnessContext } from 'contexts/WitnessContext';
import HeaderImage from '../assets/images/content/temoins-header.png';
import Illustration from '../components/Illustration';

export default function WitnessesCrudScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const { state, dispatch } = React.useContext(WitnessContext);

  const currentWitnessIndex =
    state.witnessIndexToEdit !== null ? state.witnessIndexToEdit : -1;

  const [witness, setWitness] = React.useState<Witness>(
    currentWitnessIndex >= 0
      ? constat.witnesses?.[currentWitnessIndex] || new Witness()
      : new Witness(),
  );

  const currentWitnessDisplayedIndex =
    (-1 !== currentWitnessIndex
      ? currentWitnessIndex
      : constat.witnesses?.length ?? 0) + 1;

  React.useEffect(() => {
    if (state.witnessIndexToEdit !== null) {
      selectWitness(state.witnessIndexToEdit);
    } else {
      setWitness(new Witness());
    }
  }, [state.witnessIndexToEdit, isActive]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const selectWitness = (index: number) => {
    dispatch({ type: 'EDIT', payload: index });
    let currentWitness = constat.witnesses?.[index];
    setWitness(currentWitness ? currentWitness : new Witness());
  };

  const saveWitness = () => {
    setConstat((prevConstat) => {
      return new Constat(prevConstat).pushWitness(currentWitnessIndex, witness);
    });
    setScreen(Screen.witnesses);
  };

  const handleChange = (fieldName: string, value: any) => {
    setWitness((prevWitness) => {
      let newWitness = new Witness(prevWitness);
      newWitness.setField(fieldName, value);
      return newWitness;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Illustration.Image
          src={HeaderImage}
          alt={t('screens.witnesses-crud.title', {
            num: currentWitnessDisplayedIndex,
          })}
        />
      }
    >
      <ContentContainer>
        <h2>
          {t('screens.witnesses-crud.title', {
            num: currentWitnessDisplayedIndex,
          })}
        </h2>
        <Form onSubmit={handleSubmit}>
          <InputField
            name="lastname"
            id="lastname"
            type={InputFieldType.Text}
            label={t('screens.witnesses-crud.form.lastname')}
            value={witness?.lastname || ''}
            onChange={(value) => handleChange('lastname', value)}
          />

          <InputField
            name="firstname"
            id="firstname"
            type={InputFieldType.Text}
            label={t('screens.witnesses-crud.form.firstname')}
            value={witness?.firstname || ''}
            onChange={(value) => handleChange('firstname', value)}
          />

          <InputField
            name="email"
            id="email"
            type={InputFieldType.Email}
            label={t('screens.witnesses-crud.form.email')}
            value={witness?.email || ''}
            onChange={(value) => handleChange('email', value)}
          />

          <InputField
            name="phone"
            id="phone"
            type={InputFieldType.Phone}
            label={t('screens.witnesses-crud.form.phone')}
            value={witness?.phone || ''}
            onChange={(value) => handleChange('phone', value)}
          />

          <InputField
            name="address"
            id="address"
            type={InputFieldType.GooglePlaces}
            label={t('screens.witnesses-crud.form.address')}
            value={witness?.address || ''}
            onChange={(value) => handleChange('address', value)}
          />
        </Form>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.witnesses)}
          >
            {t('common.cancel')}
          </Button>

          <Button variant={ButtonVariant.Green} onClick={saveWitness}>
            {t(currentWitnessIndex > -1 ? 'common.save' : 'common.add')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
