import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { VehicleContainerVariant } from '../partials/VehicleContainer';
import { ConstatUtilsService } from '../services/constat-utils.service';
import { Vehicle } from '../models/Vehicle';
import { VehicleSummary } from '../components/VehicleSummary';

export default function Step1SummaryScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat] = useLocalStorage('constat', new Constat());
  const constatUtilsService = new ConstatUtilsService();

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step1-summary.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={1}
      extraCssClasses={['screen-step', 'step1-summary']}
    >
      <ContentContainer>
        <VehicleSummary
          vehicle={new Vehicle(constat.vehicleA)}
          vehicleVariant={VehicleContainerVariant.A}
          title={t('screens.step1-summary.second-title')}
          onEditClick={() =>
            setScreen(
              !constat.vehicleA?.fleetVehicle
                ? Screen.step1ChoiceType
                : Screen.step1Driver,
            )
          }
        ></VehicleSummary>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.woundedList)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={
              !constatUtilsService.isVehicleComplete(
                new Vehicle(constat.vehicleA),
              )
            }
            onClick={() => setScreen(Screen.step2ChoiceType)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
