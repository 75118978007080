import React from 'react';

type TimelineProps = {
  step: number;
};

function TimelineStep({ step }: TimelineProps): JSX.Element {
  return (
    <div className={`timeline-step timeline-step-${step}`}>
      <div className="track-line" />
      <div className="track-fill" />
      <div className="thumb">{step}</div>
    </div>
  );
}

export default TimelineStep;
