import React from 'react';
import { Button, ButtonType, ButtonVariant } from './Button';
import { useTranslation } from 'react-i18next';
import { Vehicle } from '../models/Vehicle';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from './VehicleIcon';
import VehicleFieldCheck from '../partials/VehicleFieldCheck';
import { ConstatUtilsService } from '../services/constat-utils.service';

type VehicleSummaryProps = {
  vehicle: Vehicle;
  vehicleVariant: VehicleContainerVariant;
  title: string;
  onEditClick?: () => void;
};

export function VehicleSummary({
  vehicle,
  vehicleVariant,
  title,
  onEditClick,
}: VehicleSummaryProps) {
  const { t } = useTranslation();

  const constatUtilsService = new ConstatUtilsService();

  return (
    <VehicleContainer variant={vehicleVariant}>
      <h2>
        <VehicleIcon shape={vehicle?.vehicleShape}></VehicleIcon>
        {title}
      </h2>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.insured')}
        data={constatUtilsService.getVehicleInsuredFullName(vehicle)}
      ></VehicleFieldCheck>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.insuredTel')}
        data={vehicle.insuredTel}
      ></VehicleFieldCheck>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.insuredEmail')}
        data={vehicle.insuredEmail}
      ></VehicleFieldCheck>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.insuranceCompanyName')}
        data={vehicle.insuranceCompanyName}
      ></VehicleFieldCheck>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.insuranceCompanyPolicyNumber')}
        data={vehicle.insuranceCompanyPolicyNumber}
      ></VehicleFieldCheck>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.vehicleRegistrationNumber')}
        data={vehicle.vehicleRegistrationNumber}
      ></VehicleFieldCheck>
      <VehicleFieldCheck
        placeholder={t('vehicle.label.driver')}
        data={constatUtilsService.getVehicleDriverFullData(
          vehicle,
          t('vehicle.label.driverDrivingLicenceNumber'),
        )}
      ></VehicleFieldCheck>

      <Button
        variant={ButtonVariant.Green}
        type={ButtonType.Icon}
        onClick={onEditClick}
      >
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clipPath="url(#clip0_112_1819)">
            <path
              d="M1.15871 18.8379L1.16211 18.8413C1.25222 18.932 1.3594 19.0041 1.47746 19.0533C1.59553 19.1026 1.72216 19.128 1.85007 19.1281C1.95771 19.128 2.0646 19.1102 2.16648 19.0755L7.71359 17.1938L18.3581 6.54928C19.0088 5.89849 19.3744 5.01586 19.3744 4.09555C19.3743 3.17524 19.0087 2.29264 18.3579 1.64191C17.7071 0.991184 16.8245 0.625631 15.9042 0.625671C14.9839 0.625712 14.1013 0.991342 13.4505 1.64213L2.80601 12.2867L0.924449 17.8336C0.86518 18.0061 0.855719 18.1918 0.897152 18.3694C0.938585 18.5471 1.02923 18.7094 1.15871 18.8379ZM14.3345 2.52599C14.7514 2.11229 15.3152 1.88065 15.9025 1.88177C16.4898 1.8829 17.0528 2.11671 17.468 2.53201C17.8833 2.94731 18.1171 3.51025 18.1182 4.09756C18.1194 4.68487 17.8877 5.24869 17.474 5.66556L16.0742 7.06533L12.9346 3.92576L14.3345 2.52599ZM3.89625 12.9642L12.0508 4.80963L15.1903 7.9492L7.03578 16.1037L2.28445 17.7155L3.89625 12.9642Z"
              fill="currentColor"
            />
          </g>
          <defs>
            <clipPath id="clip0_112_1819">
              <rect width="20" height="20" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </Button>
    </VehicleContainer>
  );
}
