import React from 'react';
import ReactGoogleAutocomplete from 'react-google-autocomplete';

type InputFieldProps = {
  defaultValue?: string;
  value?: string;
  name?: string;
  id?: string;
  type?: InputFieldType;
  required?: boolean;
  disabled?: boolean;
  label?: string;
  setValid?: (valid: boolean) => void;
  validateWith?: (value: string) => boolean;
  onChange?: (value: string) => void;
};

export enum InputFieldType {
  Text = 'text',
  Phone = 'tel',
  Email = 'email',
  Password = 'password',
  GooglePlaces = 'google_places',
}

export function InputField({
  defaultValue,
  value,
  name,
  id,
  type = InputFieldType.Text,
  required = false,
  disabled = false,
  label,
  setValid = undefined,
  validateWith = undefined,
  onChange,
}: InputFieldProps): JSX.Element {
  const [dirty, setDirty] = React.useState(false);
  const [valid, setValidField] = React.useState(true);
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleValue(e.target.value);
  };

  const handleValue = (value: string) => {
    let validField = true;
    if (required && !value) {
      validField = false;
    } else if (!!value && validateWith) {
      validField = validateWith(value);
    }

    setValidField(validField);
    if (setValid) {
      setValid(validField);
    }
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <div className="input">
      {InputFieldType.GooglePlaces === type ? (
        <ReactGoogleAutocomplete
          className={`input__field input__field__${type}${dirty ? ' input__field_dirty' : ''}${!!value ? ' input__field_filled' : ''}${!valid ? ' input__field_error' : ''}`}
          id={id}
          apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
          //onPlaceSelected={(place) => handleValue(place.formatted_address)}
          defaultValue={value}
          options={{ types: ['address'] }}
          onBlur={(event) => {
            setDirty(true);
            handleValue(event.target.value);
          }}
        />
      ) : (
        <input
          className={`input__field${dirty ? ' input__field_dirty' : ''}${!!value ? ' input__field_filled' : ''}${!valid ? ' input__field_error' : ''}`}
          id={id}
          name={name}
          type={type}
          defaultValue={defaultValue}
          value={value}
          disabled={disabled}
          required={required}
          onChange={handleChange}
          onBlur={() => setDirty(true)}
        />
      )}
      <label className="input__label" htmlFor={id}>
        {label}
        {label && required ? <em>*</em> : <></>}
      </label>
    </div>
  );
}
