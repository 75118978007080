import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { Vehicle } from '../models/Vehicle';
import { VehicleContainerVariant } from '../partials/VehicleContainer';
import { VehicleSummary } from '../components/VehicleSummary';

export default function Step3SummaryScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat] = useLocalStorage('constat', new Constat());

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step3-summary.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={3}
      extraCssClasses={['screen-step', 'step3-summary']}
    >
      <ContentContainer>
        {!!constat.vehicleA && (
          <VehicleSummary
            vehicle={new Vehicle(constat.vehicleA)}
            vehicleVariant={VehicleContainerVariant.A}
            title={t('screens.step3-summary.vehicle-a-title')}
            onEditClick={() =>
              setScreen(
                !constat.vehicleA?.fleetVehicle
                  ? Screen.step1ChoiceType
                  : Screen.step1Driver,
              )
            }
          ></VehicleSummary>
        )}
        {!!constat.vehicleB && (
          <VehicleSummary
            vehicle={new Vehicle(constat.vehicleB)}
            vehicleVariant={VehicleContainerVariant.B}
            title={t('screens.step3-summary.vehicle-b-title')}
            onEditClick={() => setScreen(Screen.step2ChoiceType)}
          ></VehicleSummary>
        )}
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() =>
              setScreen(
                !constat.vehicleB ? Screen.step1Vehicle : Screen.step2Vehicle,
              )
            }
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.step4Photos)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
