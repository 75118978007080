import React from 'react';

type State = {
  witnessIndexToEdit: number | null;
  buttonClicked: boolean;
};

type Action =
  | { type: 'ADD' }
  | { type: 'EDIT'; payload: number | null }
  | { type: 'REMOVE'; payload: number | null };

type WitnessContextType = {
  state: State;
  dispatch: (action: Action) => void;
};

export const WitnessContext = React.createContext<WitnessContextType>({
  state: { witnessIndexToEdit: null, buttonClicked: false },
  dispatch: () => {},
});
