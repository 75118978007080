import React from 'react';

type State = {
  woundedIndexToEdit: number | null;
  buttonClicked: boolean;
};

type Action =
  | { type: 'ADD' }
  | { type: 'EDIT'; payload: number | null }
  | { type: 'REMOVE'; payload: number | null };

type WoundedContextType = {
  state: State;
  dispatch: (action: Action) => void;
};

export const WoundedContext = React.createContext<WoundedContextType>({
  state: { woundedIndexToEdit: null, buttonClicked: false },
  dispatch: () => {},
});
