import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import { Button, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import Footer from '../partials/Footer';
import GreetingsImage from '../assets/images/content/greetings.png';
import Illustration from '../components/Illustration';

export default function GreetingsScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const restart = () => {
    window.localStorage.removeItem('partner-image');
    window.localStorage.removeItem('constat');
    window.localStorage.removeItem('contexts');
    window.localStorage.removeItem('store');
    setScreen(Screen.start);
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer extraCssClasses={['greetings']}>
      <ContentContainer>
        <Illustration.Image src={GreetingsImage} alt=""></Illustration.Image>
        <p className="greetings-text">
          <Trans i18nKey={'screens.greetings.end-text'}>
            <strong></strong>
          </Trans>
        </p>
        <Button variant={ButtonVariant.Green} onClick={restart}>
          {t('common.restart')}
        </Button>
      </ContentContainer>
      <Footer />
    </ScreenContainer>
  );
}
