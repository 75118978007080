import React from 'react';
import { Vehicle } from '../models/Vehicle';
import { VehicleIcon } from './VehicleIcon';
import { useTranslation } from 'react-i18next';

/**
 * Vehicle Shape Selector properties
 */
type VehicleShapeSelectorProps = {
  vehicle: Vehicle;
  onShapeSelected: (value?: string) => void;
  allowNoShape?: boolean;
};

const VehicleShapes = ['AUTO', 'TRUCK', 'VUL', 'MOTO', 'BIKE', 'SCOOTER'];

/**
 * Vehicle Icon component
 */
export function VehicleShapeSelector({
  vehicle,
  onShapeSelected,
  allowNoShape = false,
}: VehicleShapeSelectorProps): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={'vehicle-shape-selector'}>
      {VehicleShapes.map((shape: string) => (
        <div
          key={shape}
          className={`vehicle-shape${vehicle?.vehicleShape === shape ? ' vehicle-shape-selected' : ''}`}
          onClick={() => onShapeSelected(shape)}
        >
          <VehicleIcon shape={shape}></VehicleIcon>
          <p>{t('vehicle.shape.' + shape)}</p>
        </div>
      ))}
      {allowNoShape && (
        <div
          className={`vehicle-shape${!vehicle?.vehicleShape ? ' vehicle-shape-selected' : ''}`}
          onClick={() => onShapeSelected()}
        >
          <p>{t('vehicle.no-shape')}</p>
        </div>
      )}
    </div>
  );
}
