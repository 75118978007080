import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ActionsContainer from '../partials/ActionsContainer';
import ContentContainer from '../partials/ContentContainer';
import ScreenContainer from '../partials/ScreenContainer';
import { WitnessesList } from '../components/Witness';
import { Constat } from 'models/Constat';
import { useLocalStorage } from '@uidotdev/usehooks';

export default function WitnessesListScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const [constat] = useLocalStorage('constat', new Constat());

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans
          i18nKey={
            'screens.witnesses.title.' +
            (constat.witnesses?.length ? 'withOnes' : 'empty')
          }
        >
          <span></span>
        </Trans>
      }
      extraCssClasses={['witnesses-list']}
    >
      <ContentContainer>
        <p className="text text--center">
          <Trans i18nKey={'screens.witnesses.text'}>
            <strong></strong>
          </Trans>
        </p>

        <WitnessesList witnesses={constat.witnesses} />
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.checkboxes)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() => setScreen(Screen.woundedList)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
