import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import { VehicleIcon } from '../components/VehicleIcon';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { PhotosUploader } from '../components/PhotosUploader';
import { Vehicle } from '../models/Vehicle';
import { Textarea } from '../components/Textarea';

export default function Step6DamagesAScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();
  const [constat, setConstat] = useLocalStorage('constat', new Constat());

  const handlePhotosChange = (photos: string[]) =>
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleA) {
        newConstat.vehicleA = new Vehicle();
      }
      newConstat.vehicleA.damagePictures = photos;
      return newConstat;
    });

  const handleComment = (value: string) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleA) {
        newConstat.vehicleA = new Vehicle();
      }
      newConstat.vehicleA.visibleDamage = value;
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step6-damages-a.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={6}
      extraCssClasses={['screen-step', 'step6-damages-a']}
    >
      <ContentContainer>
        <VehicleContainer variant={VehicleContainerVariant.A}>
          <h2>
            <VehicleIcon shape={constat.vehicleA?.vehicleShape}></VehicleIcon>
            {t('screens.step6-damages-a.second-title')}
          </h2>
        </VehicleContainer>
        <PhotosUploader
          id="constat-vehicle-a-damage-photos"
          name="constat-vehicle-a-damage-photos"
          photos={constat.vehicleA?.damagePictures ?? []}
          onPhotosChange={handlePhotosChange}
          multiple={true}
        ></PhotosUploader>

        <Textarea
          label={t('screens.step6-damages-a.instructions')}
          name="visibleDamage"
          id="visibleDamage"
          value={constat.vehicleA?.visibleDamage || ''}
          onChange={(value) => handleComment(value)}
        />
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step5InitialImpactA)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            onClick={() =>
              setScreen(
                !constat.vehicleB
                  ? Screen.step10SketchComments
                  : Screen.step7InitialImpactB,
              )
            }
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
