import React from 'react';
import { Screen } from '../enums/screen.enum';
import { Trans, useTranslation } from 'react-i18next';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ActionsContainer from '../partials/ActionsContainer';
import Illustration from '../components/Illustration';
import ContentContainer from '../partials/ContentContainer';
import ScreenContainer from '../partials/ScreenContainer';
import TitleImage from '../assets/images/content/securite.png';
import Checkbox from '../components/Checkbox';

export default function CheckboxesScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const [cguChecked, setCguChecked] = React.useState(false);
  const [privacyChecked, setPrivacyChecked] = React.useState(false);
  const [cookiesChecked, setCookiesChecked] = React.useState(false);
  const [isCTADisabled, setIsCTADisabled] = React.useState(true);

  React.useEffect(() => {
    setIsCTADisabled(!(cguChecked && privacyChecked && cookiesChecked));
  }, [cguChecked, privacyChecked, cookiesChecked]);

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={t('screens.checkboxes.title')}
      extraCssClasses={['checkboxes']}
    >
      <ContentContainer>
        <Illustration.Container withBorder>
          <Illustration.Image
            src={TitleImage}
            alt={t('screens.checkboxes.illustrations.warning.alt')}
          />
          <Illustration.Title>
            {t('screens.checkboxes.illustrations.warning.title')}
          </Illustration.Title>
        </Illustration.Container>

        <Checkbox
          id="cgu"
          name="cgu"
          isChecked={cguChecked}
          onChange={setCguChecked}
        >
          <Trans i18nKey="screens.checkboxes.checkboxes.cgu">
            <span onClick={() => setScreen(Screen.cgu)}></span>
          </Trans>
        </Checkbox>

        <Checkbox
          id="privacy"
          name="privacy"
          isChecked={privacyChecked}
          onChange={setPrivacyChecked}
        >
          <Trans i18nKey="screens.checkboxes.checkboxes.privacy">
            <span onClick={() => setScreen(Screen.gdpr)}></span>
          </Trans>
        </Checkbox>

        <Checkbox
          id="cookies"
          name="cookies"
          isChecked={cookiesChecked}
          onChange={setCookiesChecked}
        >
          <Trans i18nKey="screens.checkboxes.checkboxes.cookies">
            <span onClick={() => setScreen(Screen.cookies)}></span>
          </Trans>
        </Checkbox>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isDisabled={isCTADisabled}
            onClick={() => setScreen(Screen.witnesses)}
          >
            {t('screens.checkboxes.cta')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
