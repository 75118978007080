import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { ConstatUtilsService } from '../services/constat-utils.service';
import { Vehicle } from '../models/Vehicle';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import Illustration from '../components/Illustration';
import InsuredImage from '../assets/images/content/person.png';
import { Form } from '../components/Form';
import { InputField, InputFieldType } from '../components/InputField';

export default function Step1InsuredScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const constatUtilsService = new ConstatUtilsService();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());
  const [emailValid, setEmailValid] = React.useState(
    !constat?.vehicleA?.driverEmail ||
      constatUtilsService.validEmail(constat?.vehicleA?.driverEmail),
  );
  const [phoneValid, setPhoneValid] = React.useState(
    !constat?.vehicleA?.driverTel ||
      constatUtilsService.validCellphone(constat?.vehicleA?.driverTel),
  );

  const handleChange = (fieldName: string, value: any) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleA) {
        newConstat.vehicleA = new Vehicle();
      }
      newConstat.vehicleA.setField(fieldName, value);
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step1-insured.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={1}
      extraCssClasses={['screen-step', 'step1-insured']}
    >
      <ContentContainer>
        <h3>{t('screens.step1-insured.instructions')}</h3>
        <VehicleContainer variant={VehicleContainerVariant.A}>
          <h2>
            <Illustration.Image src={InsuredImage} alt=""></Illustration.Image>
            {t('screens.step1-insured.second-title')}
          </h2>
        </VehicleContainer>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <InputField
            name="insuredName"
            id="insuredName"
            type={InputFieldType.Text}
            required={true}
            label={t('vehicle.label.insuredName')}
            value={constat.vehicleA?.insuredName || ''}
            onChange={(value) => handleChange('insuredName', value)}
          />
          {!constat.vehicleA?.fleetVehicle && (
            <InputField
              name="insuredFirstname"
              id="insuredFirstname"
              type={InputFieldType.Text}
              required={true}
              label={t('vehicle.label.insuredFirstname')}
              value={constat.vehicleA?.insuredFirstname || ''}
              onChange={(value) => handleChange('insuredFirstname', value)}
            />
          )}
          <InputField
            name="insuredTel"
            id="insuredTel"
            type={InputFieldType.Phone}
            required={true}
            label={t('vehicle.label.insuredTel')}
            value={constat.vehicleA?.insuredTel || ''}
            setValid={setPhoneValid}
            validateWith={constatUtilsService.validCellphone}
            onChange={(value) => handleChange('insuredTel', value)}
          />
          <InputField
            name="insuredEmail"
            id="insuredEmail"
            type={InputFieldType.Email}
            required={true}
            label={t('vehicle.label.insuredEmail')}
            value={constat.vehicleA?.insuredEmail || ''}
            setValid={setEmailValid}
            validateWith={constatUtilsService.validEmail}
            onChange={(value) => handleChange('insuredEmail', value)}
          />
        </Form>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step1Driver)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={
              !emailValid ||
              !phoneValid ||
              !constatUtilsService.isVehicleInsuredComplete(
                new Vehicle(constat.vehicleA),
              )
            }
            onClick={() => setScreen(Screen.step1Vehicle)}
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
