import { AbstractModel } from './Abstract.model';

export class Vehicle extends AbstractModel {
  /** Copy constructor */
  constructor(fromObj?: object) {
    super();
    Object.assign(this, fromObj);
  }

  vehicleShape?: string;
  fleetVehicle?: string;

  // 6 - Insured
  insuredName?: string;
  insuredFirstname?: string;
  insuredAddress?: string;
  insuredPortalCode?: string;
  insuredTown?: string;
  insuredCountry?: string;
  insuredTel?: string;
  insuredEmail?: string;

  // 7 - Vehicle
  vehicleMake?: string;
  vehicleType?: string;
  vehicleRegistrationNumber?: string;
  trailerRegistrationNumber?: string;
  vehicleCountryOfRegistration?: string;
  trailerCountryOfRegistration?: string;

  // 8 - Insurance company
  insuranceCompanyName?: string;
  insuranceCompanyPolicyNumber?: string;
  insuranceCompanyInsuranceCertificateNumber?: string;
  insuranceCompanyInsuranceValidityFrom?: string;
  insuranceCompanyInsuranceValidityTo?: string;
  insuranceCompanyAgencyOrBrokerName?: string;
  insuranceCompanyAgencyOrBrokerAddress?: string;
  insuranceCompanyAgencyOrBrokerCountry?: string;
  insuranceCompanyAgencyOrBrokerTelOrEmail?: string;
  insuranceCompanyDamageToVehicleInsuredByTheContract?: boolean;

  // 9 - Driver
  driverName?: string;
  driverFirstname?: string;
  driverDateOfBirth?: string;
  driverAddress?: string;
  driverCountry?: string;
  driverTel?: string;
  driverEmail?: string;
  driverDrivingLicenceNumber?: string;
  driverGroups?: string;
  driverDrivingLicenceValidUntil?: string;

  // 10 - Initial impact
  initialImpact?: string;

  // 11 - Visible damage
  visibleDamage?: string;
  damagePictures?: Array<string>;

  // 12 - Circumstances
  circumstances?: Array<number>;

  // 14 - Remarks
  remarks?: string;

  // Additional page
  comments?: string;

  // Liability page
  liability?: string;
  fault?: string;
}
