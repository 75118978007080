import React from 'react';

type VehicleContainerProps = {
  variant: VehicleContainerVariant;
  children: React.ReactNode;
};

export enum VehicleContainerVariant {
  A = 'vehicle-a',
  B = 'vehicle-b',
}

function VehicleContainer({
  variant,
  children,
}: VehicleContainerProps): JSX.Element {
  return <div className={`vehicle ${variant}`}>{children}</div>;
}

export default VehicleContainer;
