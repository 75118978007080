import React from 'react';
import { Trans } from 'react-i18next';
import { useLocalStorage } from '@uidotdev/usehooks';
import DefaultPartnerImage from '../assets/images/brand/Logo.svg';
import * as process from 'process';

type HeaderProps = {
  isMinimized?: boolean;
  lastScreen?: boolean;
};

function Header({
  isMinimized = false,
  lastScreen = false,
}: HeaderProps): JSX.Element {
  const [partnerImage] = useLocalStorage('partner-image', DefaultPartnerImage);

  return (
    <header className={`header ${isMinimized ? 'header--minimized' : ''}`}>
      <section className="header__brand">
        {'production' !== process.env.REACT_APP_ENV && (
          <span className={'environment-indicator'}>
            {process.env.REACT_APP_ENV}
          </span>
        )}
        <img className="header__brand-logo" src={partnerImage} alt="Logo" />
        {!isMinimized && (
          <p className="header__brand-text">
            <Trans
              i18nKey={
                lastScreen
                  ? 'partials.header.greetings-text'
                  : partnerImage !== DefaultPartnerImage
                    ? 'partials.header.partner-text'
                    : 'partials.header.default-text'
              }
            >
              <strong></strong>
            </Trans>
          </p>
        )}
      </section>
    </header>
  );
}

export default Header;
