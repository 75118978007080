import { Vehicle } from '../models/Vehicle';
import { Constat } from '../models/Constat';

export class ConstatUtilsService {
  escapeQuotes = (str: string) => String(str).replace(/'/g, "\\'");
  removeQuotes = (str: string) => String(str).replace(/'/g, '');
  htmlEntities = (str: string) =>
    String(str)
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');

  public isVehicleDriverComplete(vehicle: Vehicle): boolean {
    return (
      !!vehicle?.driverName &&
      !!vehicle?.driverFirstname &&
      !!vehicle?.driverTel &&
      !!vehicle?.driverAddress &&
      !!vehicle?.driverEmail &&
      (!this.drivingLicenceNumberRequired(vehicle) ||
        !!vehicle?.driverDrivingLicenceNumber)
    );
  }

  public isVehicleInsuredComplete(vehicle: Vehicle): boolean {
    return (
      !!vehicle?.insuredName &&
      (!!vehicle.fleetVehicle || !!vehicle?.insuredFirstname) &&
      !!vehicle?.insuredTel &&
      !!vehicle?.insuredEmail
    );
  }

  public isVehicleLastDataComplete(vehicle: Vehicle): boolean {
    return (
      !!vehicle?.vehicleRegistrationNumber &&
      !!vehicle?.insuranceCompanyName &&
      !!vehicle?.insuranceCompanyPolicyNumber
    );
  }

  public isVehicleComplete(vehicle: Vehicle): boolean {
    return (
      // Shape OK
      !!vehicle?.vehicleShape &&
      // Driver OK
      this.isVehicleDriverComplete(vehicle) &&
      // Insured OK
      this.isVehicleInsuredComplete(vehicle) &&
      // Vehicle last data OK
      this.isVehicleLastDataComplete(vehicle)
    );
  }

  public getVehicleInsuredFullName(vehicle: Vehicle): string | undefined {
    return vehicle.insuredName || vehicle.insuredFirstname
      ? `${vehicle.insuredFirstname ?? ''}${!!vehicle.insuredName && !!vehicle.insuredFirstname ? ' ' : ''}${vehicle.insuredName ?? ''}`
      : undefined;
  }

  public getVehicleDriverFullData(
    vehicle: Vehicle,
    driverDrivingLicenceNumberLabel: string,
  ): string | undefined {
    return vehicle.driverName &&
      vehicle.driverFirstname &&
      vehicle.driverTel &&
      vehicle.driverEmail &&
      (!this.drivingLicenceNumberRequired(vehicle) ||
        !!vehicle?.driverDrivingLicenceNumber)
      ? `${vehicle.driverFirstname} ${vehicle.driverName}<br>
      ${vehicle.driverTel}<br>
      ${vehicle.driverEmail}${
        this.drivingLicenceNumberRequired(vehicle)
          ? `<br>
      ${driverDrivingLicenceNumberLabel}: ${vehicle.driverDrivingLicenceNumber}`
          : ''
      }`
      : undefined;
  }

  public isConstatLocalizationDataComplete(constat: Constat): boolean {
    return (
      !!constat?.dateOfAccident &&
      !!constat?.hourOfAccident &&
      !!constat?.localityExactLocation
    );
  }

  public getChatbotPersonA(constat: Constat): string {
    return this.escapeQuotes(
      this.htmlEntities(
        (constat.vehicleA?.driverFirstname === constat.vehicleB?.driverFirstname
          ? `${constat.vehicleA?.driverFirstname} ${constat.vehicleA?.driverName}`
          : constat.vehicleA?.driverFirstname) ?? '',
      ),
    );
  }

  public getChatbotPersonB(constat: Constat): string {
    return this.escapeQuotes(
      this.htmlEntities(
        (constat.vehicleA?.driverFirstname === constat.vehicleB?.driverFirstname
          ? `${constat.vehicleB?.driverFirstname} ${constat.vehicleB?.driverName}`
          : constat.vehicleB?.driverFirstname) ?? '',
      ),
    );
  }

  public drivingLicenceNumberRequired = (vehicle?: Vehicle): boolean =>
    !['BIKE', 'SCOOTER'].includes(vehicle?.vehicleShape ?? '');

  public vehicleRegistrationNumberRequired = (vehicle?: Vehicle): boolean =>
    !['BIKE', 'SCOOTER'].includes(vehicle?.vehicleShape ?? '');

  public validEmail = (email?: string): boolean =>
    !!email?.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/gi,
    );

  public validPhone = (phone?: string): boolean =>
    !!phone?.match(/^(\+33|0)[0-9]{9}$/gi);

  public validCellphone = (cellphone?: string): boolean =>
    !!cellphone?.match(/^(\+33|0)[6-7][0-9]{8}$/gi);

  public validDate = (date?: string): boolean =>
    !!date?.match(/^(0[1-9]|[12][0-9]|3[01])[/-](0[1-9]|1[012])[/-]\d{4}$/gi);

  public validHour = (hour?: string): boolean =>
    !!hour?.match(/^([0-1][0-9]|2[0-3])[h|:]([0-5][0-9])$/gi);
}
