export abstract class AbstractModel {
  /** Copy constructor */
  constructor(fromObj?: object) {
    Object.assign(this, fromObj);
  }

  setField(field: string, value: string): this {
    // @ts-ignore
    this[field] = value;
    return this;
  }
}
