import React from 'react';

type ToggleProps = {
  isChecked?: boolean;
  readonly?: boolean;
  name?: string;
  id?: string;
  leftLabel?: string;
  rightLabel?: string;
  onChange?: (checked: boolean) => void;
};

function Toggle({
  isChecked = false,
  readonly = false,
  id,
  name,
  leftLabel = '',
  rightLabel = '',
  onChange,
}: ToggleProps): JSX.Element {
  const [checked, setChecked] = React.useState(isChecked);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(e.target.checked);
    }

    setChecked(e.target.checked);
  };

  return (
    <div className="input">
      <label htmlFor={id} className="input__toggle">
        <input
          className="input__field"
          id={id}
          name={name}
          type="checkbox"
          checked={checked}
          disabled={readonly}
          onChange={handleChange}
        />

        <span className="input__label input__left_label">{leftLabel}</span>
        <span className="input__toggle-widget">
          {checked ? (
            <svg
              width="42"
              height="25"
              viewBox="0 0 42 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.7146"
                y="0.197205"
                width="41.276"
                height="24.6482"
                rx="12.3241"
                fill="#3AB54A"
              />
              <rect
                x="2.4751"
                y="1.95773"
                width="37.7548"
                height="21.127"
                rx="10.5635"
                fill="#3AB54A"
              />
              <circle cx="29.8155" cy="12.5212" r="10.5635" fill="white" />
            </svg>
          ) : (
            <svg
              width="42"
              height="25"
              viewBox="0 0 42 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                y="0.197205"
                width="41.276"
                height="24.6482"
                rx="12.3241"
                fill="#0F1223"
              />
              <rect
                x="1.76038"
                y="1.95773"
                width="37.7548"
                height="21.127"
                rx="10.5635"
                fill="#0F1223"
              />
              <circle cx="12.3239" cy="12.5212" r="10.5635" fill="white" />
            </svg>
          )}
        </span>
        <span className="input__label input__right_label">{rightLabel}</span>
      </label>
    </div>
  );
}

export default Toggle;
