import React from 'react';
import { Screen } from '../enums/screen.enum';
import { useTranslation, Trans } from 'react-i18next';
import ContentContainer from '../partials/ContentContainer';
import ActionsContainer from '../partials/ActionsContainer';
import { Button, ButtonGroup, ButtonVariant } from '../components/Button';
import ScreenContainer from '../partials/ScreenContainer';
import { useLocalStorage } from '@uidotdev/usehooks';
import { Constat } from '../models/Constat';
import { ConstatUtilsService } from '../services/constat-utils.service';
import { Vehicle } from '../models/Vehicle';
import VehicleContainer, {
  VehicleContainerVariant,
} from '../partials/VehicleContainer';
import Illustration from '../components/Illustration';
import DriverImage from '../assets/images/content/person.png';
import { Form } from '../components/Form';
import { InputField, InputFieldType } from '../components/InputField';
import Toggle from '../components/Toggle';

export default function Step2DriverScreen({
  isActive,
  setScreen,
}: {
  isActive: boolean;
  setScreen: (screen: Screen) => void;
}) {
  const { t } = useTranslation();

  const constatUtilsService = new ConstatUtilsService();

  const [constat, setConstat] = useLocalStorage('constat', new Constat());
  const [emailValid, setEmailValid] = React.useState(
    !constat?.vehicleB?.driverEmail ||
      constatUtilsService.validEmail(constat?.vehicleB?.driverEmail),
  );
  const [phoneValid, setPhoneValid] = React.useState(
    !constat?.vehicleB?.driverTel ||
      constatUtilsService.validCellphone(constat?.vehicleB?.driverTel),
  );
  const [driverIsInsured, setDriverIsInsured] = React.useState(
    !constat?.vehicleB?.fleetVehicle,
  );

  React.useEffect(() => {
    if (isActive && driverIsInsured) {
      setConstat((prevConstat) => {
        const newConstat = new Constat(prevConstat);
        if (!newConstat.vehicleB) {
          newConstat.vehicleB = new Vehicle();
        }
        newConstat.vehicleB.insuredName = newConstat.vehicleB.driverName;
        newConstat.vehicleB.insuredFirstname =
          newConstat.vehicleB.driverFirstname;
        newConstat.vehicleB.insuredTel = newConstat.vehicleB.driverTel;
        newConstat.vehicleB.insuredEmail = newConstat.vehicleB.driverEmail;
        return newConstat;
      });
    }
  }, [driverIsInsured]);

  const handleChange = (
    fieldName: string,
    value: any,
    insuredFieldName?: string,
  ) => {
    setConstat((prevConstat) => {
      const newConstat = new Constat(prevConstat);
      if (!newConstat.vehicleB) {
        newConstat.vehicleB = new Vehicle();
      }
      newConstat.vehicleB.setField(fieldName, value);
      if (driverIsInsured && insuredFieldName) {
        newConstat.vehicleB.setField(insuredFieldName, value);
      }
      return newConstat;
    });
  };

  return !isActive ? (
    <></>
  ) : (
    <ScreenContainer
      title={
        <Trans i18nKey={'screens.step2-driver.title'}>
          <strong></strong>
        </Trans>
      }
      timelineStep={2}
      extraCssClasses={['screen-step', 'step2-driver']}
    >
      <ContentContainer>
        <h3>{t('screens.step2-driver.instructions')}</h3>
        <VehicleContainer variant={VehicleContainerVariant.B}>
          <h2>
            <Illustration.Image src={DriverImage} alt=""></Illustration.Image>
            {t('screens.step2-driver.second-title')}
          </h2>
        </VehicleContainer>
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
            e.preventDefault();
          }}
        >
          <InputField
            name="driverName"
            id="driverName"
            type={InputFieldType.Text}
            required={true}
            label={t('vehicle.label.driverName')}
            value={constat.vehicleB?.driverName || ''}
            onChange={(value) =>
              handleChange('driverName', value, 'insuredName')
            }
          />
          <InputField
            name="driverFirstname"
            id="driverFirstname"
            type={InputFieldType.Text}
            required={true}
            label={t('vehicle.label.driverFirstname')}
            value={constat.vehicleB?.driverFirstname || ''}
            onChange={(value) =>
              handleChange('driverFirstname', value, 'insuredFirstname')
            }
          />
          <InputField
            name="driverTel"
            id="driverTel"
            type={InputFieldType.Phone}
            required={true}
            label={t('vehicle.label.driverTel')}
            value={constat.vehicleB?.driverTel || ''}
            setValid={setPhoneValid}
            validateWith={constatUtilsService.validCellphone}
            onChange={(value) => handleChange('driverTel', value, 'insuredTel')}
          />
          <InputField
            name="driverAddress"
            id="driverAddress"
            type={InputFieldType.GooglePlaces}
            required={true}
            label={t('vehicle.label.driverAddress')}
            value={constat.vehicleB?.driverAddress || ''}
            onChange={(value) => handleChange('driverAddress', value)}
          />
          <InputField
            name="driverEmail"
            id="driverEmail"
            type={InputFieldType.Email}
            required={true}
            label={t('vehicle.label.driverEmail')}
            value={constat.vehicleB?.driverEmail || ''}
            setValid={setEmailValid}
            validateWith={constatUtilsService.validEmail}
            onChange={(value) =>
              handleChange('driverEmail', value, 'insuredEmail')
            }
          />
          {constatUtilsService.drivingLicenceNumberRequired(
            constat.vehicleB,
          ) && (
            <InputField
              name="driverDrivingLicenceNumber"
              id="driverDrivingLicenceNumber"
              type={InputFieldType.Text}
              required={true}
              label={t('vehicle.label.driverDrivingLicenceNumber')}
              value={constat.vehicleB?.driverDrivingLicenceNumber || ''}
              onChange={(value) =>
                handleChange('driverDrivingLicenceNumber', value)
              }
            />
          )}
        </Form>

        <p className={'question'}>
          {t('screens.step2-driver.ask-driver-insured')}
        </p>
        <Toggle
          id={'driverIsInsured'}
          name={'driverIsInsured'}
          leftLabel={t('common.no')}
          rightLabel={t('common.yes')}
          isChecked={driverIsInsured}
          onChange={setDriverIsInsured}
        ></Toggle>
      </ContentContainer>

      <ActionsContainer>
        <ButtonGroup>
          <Button
            variant={ButtonVariant.Green}
            isOutlined
            onClick={() => setScreen(Screen.step2ChoiceType)}
          >
            {t('common.previous')}
          </Button>

          <Button
            variant={ButtonVariant.Green}
            isDisabled={
              !emailValid ||
              !phoneValid ||
              !constatUtilsService.isVehicleDriverComplete(
                new Vehicle(constat.vehicleB),
              )
            }
            onClick={() =>
              setScreen(
                driverIsInsured ? Screen.step2Vehicle : Screen.step2Insured,
              )
            }
          >
            {t('common.next')}
          </Button>
        </ButtonGroup>
      </ActionsContainer>
    </ScreenContainer>
  );
}
