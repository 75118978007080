import React from 'react';

type FormProps = {
  children: React.ReactNode;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
};

export const Form = React.forwardRef<HTMLFormElement, FormProps>(
  ({ children, onSubmit }, ref: React.ForwardedRef<HTMLFormElement>) => {
    return (
      <form className="form" onSubmit={onSubmit} ref={ref}>
        {children}
      </form>
    );
  },
);

type FormGroupProps = {
  children: React.ReactNode;
};

export function FormGroup({ children }: FormGroupProps): JSX.Element {
  return <div className="form__group">{children}</div>;
}
